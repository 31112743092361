import { lead } from "../constant";

const initialState = {
  data: "",
};

export const leadListReducer = (state = initialState, action) => {
  switch (action.type) {
    case lead.LEAD_LIST:
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

export const searchLeadListReducer = (state = initialState, action) => {
  switch (action.type) {
    case lead.SEARCH_LEAD:
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

export const createLeadReducer = (state = initialState, action) => {
  switch (action.type) {
    case lead.CREATE_LEAD:
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};
export const updateLeadReducer = (state = initialState, action) => {
  switch (action.type) {
    case lead.UPDATE_LEAD:
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

export const mobileNumberCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case lead.MOBLIE_NUMBER_CHECK:
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

export const singleLeadDataActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case lead.SINGLE_LEAD_DATA:
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

export const leadReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case lead.LEAD_REPORT:
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};
