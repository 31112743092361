import { BASE_URL_V2, org_id } from "../../config";
import axios from "axios";
import SessionExpireError from "../../helpers/sessionExpireError";
import { notification } from "antd";
import { headers } from "../../services/api-service";

export const updateStartEndAttendance = async (data) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/activity/attendance/check/`;

  return await axios
    .post(url, data, { headers })
    .then((response) => {
      if (response.status == 200) {
        notification.success({
          message: `${response.data.message}`,
        });
      }
      return !response.data.error;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: `${err.response.data.message}`,
      });
    });
};

export const getattandanceDetail = async (id) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/activity/attendance/${
    id || "check"
  }/`;

  return await axios
    .get(url, { headers })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: `${err.response?.data?.message}`,
      });
    });
};

export const getStartEndDayAttandanceDetail = async (id) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/attendance/${id}/`;

  return await axios
    .get(url, { headers })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: `${err.response.data.message}`,
      });
    });
};
