export default function OrderIcon({ fill = "#727176", size = "24" }) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <mask
        id="mask0_1144_373"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="3"
        width="23"
        height="24"
      >
        <path d="M26.2301 3.75H4.16675V26.5515H26.2301V3.75Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1144_373)">
        <path
          d="M9.13352 3.75C8.88336 3.75044 8.63772 3.80997 8.42115 3.92263C8.20457 4.03529 8.02464 4.19714 7.89935 4.392L4.69852 9.39H14.481V3.75H9.13352ZM4.16602 10.725V24.9803C4.16602 25.398 4.35039 25.7986 4.67858 26.0939C5.00677 26.3893 5.45189 26.5553 5.91602 26.5553H24.4835C24.9476 26.5553 25.3928 26.3893 25.721 26.0939C26.0491 25.7986 26.2335 25.398 26.2335 24.9803V10.725H4.16602ZM18.5685 16.719L14.7285 20.175C14.6596 20.2371 14.5778 20.2864 14.4877 20.3201C14.3976 20.3537 14.301 20.371 14.2035 20.371C14.106 20.371 14.0094 20.3537 13.9193 20.3201C13.8292 20.2864 13.7474 20.2371 13.6785 20.175L11.8785 18.555C11.8084 18.4932 11.7526 18.4195 11.7144 18.3381C11.6762 18.2568 11.6562 18.1694 11.6557 18.0811C11.6552 17.9928 11.6742 17.9052 11.7115 17.8236C11.7488 17.7419 11.8038 17.6677 11.8732 17.6052C11.9426 17.5427 12.025 17.4933 12.1158 17.4597C12.2066 17.4261 12.3038 17.409 12.402 17.4095C12.5001 17.4099 12.5972 17.4279 12.6875 17.4623C12.7779 17.4967 12.8598 17.5469 12.9285 17.61L14.2035 18.7575L17.5193 15.7733C17.588 15.7102 17.6699 15.66 17.7603 15.6256C17.8507 15.5911 17.9478 15.5732 18.0459 15.5727C18.144 15.5723 18.2413 15.5894 18.3321 15.623C18.4228 15.6565 18.5053 15.706 18.5747 15.7685C18.6441 15.8309 18.699 15.9051 18.7363 15.9868C18.7737 16.0685 18.7926 16.156 18.7921 16.2444C18.7916 16.3327 18.7717 16.42 18.7335 16.5014C18.6952 16.5827 18.6394 16.6564 18.5693 16.7183M22.4993 4.3905C22.3737 4.1957 22.1934 4.03401 21.9765 3.92161C21.7597 3.8092 21.5138 3.75003 21.2635 3.75H15.966V9.39H25.7102L22.4993 4.3905Z"
          {...{ fill }}
        />
      </g>
    </svg>
  );
}
