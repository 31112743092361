export default function CustomSwitch({ value, onChange, disabled }) {
  return (
    <div
      className={`custom-toggle-container ${value ? "active" : ""}`}
      style={{ ...(disabled && { opacity: 0.4, cursor: "not-allowed" }) }}
      onClick={() => {
        if (disabled) return;
        onChange(!value);
      }}
    >
      <div className={`custom-toggle-track ${value ? "active" : ""}`}>
        <div className={`custom-toggle-thumb ${value ? "active" : ""}`} />
      </div>
    </div>
  );
}
