import axios from "axios";
import { BASE_URL_V1, BASE_URL_V2, org_id } from "../../config";
import { auth } from "../constant";
import { notification } from "antd";
import Cookies from "universal-cookie";
import SessionExpireError from "../../helpers/sessionExpireError";
import { headers } from "../../services/api-service";

const cookies = new Cookies();

export const authLogin = (formInput) => (dispatch) => {
  const url = `${BASE_URL_V1}/user/initiate_login/`;
  const data = formInput;

  axios
    .post(url, data)
    .then((response) => {
      dispatch({
        type: auth.LOGIN,
        payload: response,
      });
    })
    .catch((err) => {
      notification.warning({
        message: `${err.response?.data?.message}`,
      });
    });
};

export const authVarifyOTP = (formInput) => (dispatch) => {
  const url = `${BASE_URL_V1}/user/logged_in/`;
  const data = formInput;

  axios
    .post(url, data)
    .then((response) => {
      dispatch({
        type: auth.VARIFY_OTP,
        payload: response,
      });
    })
    .catch((err) => {
      if (err.response.status === 400) {
        notification.warning({
          message: "Invalid OTP",
        });
      }
      // notification.warning({
      //   message: `${err.response.data.message}`,
      // });
    });
};

export const getUserDetails = async () => {
  let url =
    cookies.get("rupyzAccessType") === "WEB_SARE360"
      ? `${BASE_URL_V1}/user/profile/`
      : `${BASE_URL_V2}/organization/staff/profile/`;

  const params = { org_id };
  return axios
    .get(url, { headers, params })
    .then((response) => {
      cookies.set(
        "checkIn",
        response.data.data.checkin_time === ""
          ? ""
          : response.data.data.checkin_time !== "" &&
            response.data.data.checkout_time !== ""
          ? "Check Out"
          : response.data.data.checkin_time !== "" &&
            response.data.data.checkout_time === "" &&
            "Check In",
        { path: "/" }
      );
      cookies.set("rupyzPermissionType", response.data.data.permissions, {
        path: "/",
      });
      return response;
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};
