import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ContextState from "./context/ContextState";
import { ConfigProvider } from "antd";
import SentryWrapper from "./sentry";

const root = ReactDOM.createRoot(document.getElementById("root"));
const styles = getComputedStyle(document.body);

const configProviderProps = {
  modal: { className: "depreciate" },
  drawer: { className: "depreciate" },
  dropdown: { className: "depreciate" },
  theme: {
    token: {
      fontFamily: styles.getPropertyValue("--font-family"),
    },
  },
};

root.render(
  <SentryWrapper>
    <ConfigProvider {...configProviderProps}>
      <ContextState>
        <div className="app-root">
          <App />
        </div>
      </ContextState>
    </ConfigProvider>
  </SentryWrapper>
);

reportWebVitals();
