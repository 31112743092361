import { BASE_URL_V2, org_id } from "../../config";
import axios from "axios";
import SessionExpireError from "../../helpers/sessionExpireError";
import { notification } from "antd";
import { headers } from "../../services/api-service";

export const getCustomActivityList = async (params) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/activity/followup/?dd=true`;
  return await axios
    .get(url, { headers, params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const createCustomActivity = async (data, id) => {
  const url = id
    ? `${BASE_URL_V2}/organization/${org_id}/activity/followup/${id}/`
    : `${BASE_URL_V2}/organization/${org_id}/activity/followup/`;

  return await axios
    .post(url, data, { headers })
    .then((response) => {
      notification.success({ message: response?.data?.message });
      return response;
    })
    .catch((error) => {
      SessionExpireError(error.response);
      notification.warning({ message: error?.response?.data?.message });
    });
};

export const deleteCustomActivity = async (id) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/activity/followup/${id}/delete/`;

  return await axios
    .post(url, {}, { headers })
    .then((response) => {
      notification.success({ message: response?.data?.message });
      return response;
    })
    .catch((error) => {
      SessionExpireError(error.response);
      notification.warning({ message: error?.response?.data?.message });
    });
};
