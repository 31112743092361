import axios from "axios";
import { BASE_URL_V2, org_id } from "../../config";
import SessionExpireError from "../../helpers/sessionExpireError";
import { notification } from "antd";
import { headers } from "../../services/api-service";

export const callingCities = async () => {
  const url = `${BASE_URL_V2}/organization/${org_id}/dd/cities/`;

  return await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: `${err.response.data.message}`,
      });
    });
};
