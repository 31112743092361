import patterns from "../constants/regex";

export const createValidationProps = ({ name, regex }) => {
  return {
    onKeyPress: (e) => {
      if (!(name ? patterns[name] : regex).test(e.key)) {
        e.preventDefault();
      }
    },
  };
};
