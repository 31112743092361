import { useEffect } from "react";
import Cookies from "universal-cookie";
import { Spin } from "antd";
import { useNavigate } from "react-router";
import { removeAllCookies } from "../../helpers";
import { getUserDetails } from "../../redux/action/authAction";
import authService from "../../services/auth-service";

const StaffLogin = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  const fetchUserDetails = async () => {
    const res = await getUserDetails();
    if (res && res.status === 200) {
      navigate("/web");
      window.location.reload();
    }
  };

  const decodeToken = () => {
    const decodedObject = JSON.parse(atob(token));
    return decodedObject;
  };

  useEffect(() => {
    // clearin all cookies and localStorage
    removeAllCookies();

    const userDetails = decodeToken();

    // saving the imp value for login
    cookies.set("rupyzToken", `Bearer ${decodeToken().token}`, { path: "/" });
    cookies.set("rupyzUserName", decodeToken().staff_name, { path: "/" });
    cookies.set("rupyzAccessType", "WEB_STAFF", { path: "/" });

    authService.setUserDetails({
      ...userDetails,
      access_type: "WEB_STAFF",
      profile_pic: userDetails.staff_img,
    });

    setTimeout(fetchUserDetails, 500);
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin tip="Loading..." size="large"></Spin>
    </div>
  );
};

export default StaffLogin;
