import * as Sentry from "@sentry/react";

function SentryWrapper({ children }) {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      tracePropagationTargets: [
        process.env.REACT_APP_BASE_URL_V1,
        process.env.REACT_APP_BASE_URL_V2,
      ], // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    return (
      <Sentry.ErrorBoundary fallback="An error has occurred">
        {children}
      </Sentry.ErrorBoundary>
    );
  } else return children;
}

export default SentryWrapper;
