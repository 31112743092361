import { Input } from "antd";
import { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import filterService from "../../services/filter-service";

const SearchInput = ({ params, onChange, searchKey = "query" }) => {
  const [search, setSearch] = useState(
    searchKey ? filterService.getFilters()?.[searchKey] : ""
  );

  const [timer, setTimer] = useState(null);

  const handleDebounce = (e) => {
    const v = e.target.value;
    setSearch(v);
    if (timer) clearTimeout(timer);
    const debounce = setTimeout(() => {
      onChange(v);
    }, 500);
    setTimer(debounce);
  };

  return (
    <Input
      data-testid="search-input"
      className="custom-search-input"
      placeholder="Search . . ."
      value={search}
      {...params}
      addonAfter={<SearchOutlined />}
      onChange={handleDebounce}
    />
  );
};

export default SearchInput;
