import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import { setupInterface } from "./interface";
import apiService from "./services/api-service";
import SvgDefs from "./refactor/components/SvgDefs";
import MaintenancePage from "./refactor/pages/Maintenance";
import Login from "./refactor/pages/Login";
// import Login from "./auth/login";
// pending refactor/removal :-
import { PrivateRoute } from "./components/customRouters/privateRouter";
import { PublicRouter } from "./components/customRouters/publicRoute";
import StaffLogin from "./auth/staffLogin";
import { Provider } from "react-redux";
import store from "./redux/store";
import ScrollTop from "./components/scrollToTop";
import Loader from "./components/loader/Loader";
import DashboardRoutes, { Refactor } from "./routes/dashboardRoutes";

const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

function App() {
  const [appStatus, setAppStatus] = useState({});

  const checkForMaintenance = async () => {
    const res = await apiService.fetchAppStatus();
    if (res && res.status === 200) {
      setAppStatus(res.data.data);
      return true;
    }
    return false;
  };

  useEffect(() => {
    checkForMaintenance().then((isActive) => {
      if (isActive) {
        setupInterface();
      }
    });
  }, []);

  if (!Object.keys(appStatus).length) return <div />;
  else if (appStatus?.maintenance_status) return <MaintenancePage />;
  else
    return (
      <LoadScript
        googleMapsApiKey={GOOGLE_MAPS_KEY}
        libraries={["places"]}
        loadingElement={<div></div>}
      >
        <SvgDefs />
        <BrowserRouter>
          <Provider store={store}>
            <ScrollTop>
              <Routes>
                <Route
                  path="/"
                  element={
                    <PublicRouter>
                      <Login />
                    </PublicRouter>
                  }
                />
                <Route path="/staff-login" element={<StaffLogin />} />
                <Route
                  path="/web/*"
                  element={
                    <PrivateRoute>
                      <DashboardRoutes />
                    </PrivateRoute>
                  }
                />
              </Routes>
              {/*  pending refactor/removal :- */}
              <Loader />
            </ScrollTop>
          </Provider>
        </BrowserRouter>
      </LoadScript>
    );
}

export default App;
