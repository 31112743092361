import dayjs from "dayjs";
import axios from "axios";
import moment from "moment";
import { DatePicker, Spin } from "antd";
import SelectStaff from "./SelectStaff";
import FieldAction from "./FieldAction";
import { useEffect, useState } from "react";
import {
  createBeatPlan,
  getBeatPlanDetails,
} from "../../../redux/action/beatPlanAction";
import { useNavigate } from "react-router-dom";
import styles from "../beatPlanList.module.css";
import { ArrowLeft } from "../../../assets/globle";
import { BASE_URL_V2, org_id } from "../../../config";
import AdminLayout from "../../../components/AdminLayout";
import { GeneralModal } from "../../../components/modals";
import { Staff as staffIcon } from "../../../assets/navbarImages";
import { headers } from "../../../services/api-service";
import authService from "../../../services/auth-service";
import hasAccess from "../../../services/permissions";

export default function AddBeatPlan() {
  const navigate = useNavigate();

  const { RangePicker } = DatePicker;

  const authDetails = authService.fetchUserDetails() || {};
  const isAdmin = hasAccess();
  const queryParameters = new URLSearchParams(window.location.search);
  const plan_id = queryParameters.get("plan_id");

  const [plannedDates, setPlannedDates] = useState({
    start_date: "",
    end_date: "",
  });
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [beatList, setBeatList] = useState([]);
  const [dayWiseDetails, setDayWiseDetails] = useState({});
  const [openChooseStaffWindow, setOpenChooseStaffWindow] = useState(
    isAdmin && !plan_id
  );
  const [warningForStaffChange, setWarningForStaffChange] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState({
    id: authDetails?.staff_id || 0,
    user_id: !isAdmin || !authDetails?.hierarchy ? authDetails?.user_id : 0,
    name: isAdmin ? "" : "Self",
  });

  const isPlanStarted = plan_id
    ? moment(plannedDates.start_date) <= moment()
    : false;

  const fetchBeatList = async (staff_id) => {
    if (!staff_id) return;

    setLoader(true);

    let page_no = 1,
      data = [],
      flag = true;

    while (flag) {
      const url = `${BASE_URL_V2}/organization/${org_id}/beat/`;
      const params = {
        page_no: page_no++,
        dd: true,
        staff_id,
      };
      const res = await axios.get(url, { headers, params });
      data = [...data, ...res.data.data];
      if (res.data.data.length < 30) flag = false;
    }

    setBeatList(data);

    setLoader(false);
    return data;
  };

  const updateValues = (key, value) => {
    let prevValues = dayWiseDetails;
    prevValues = { ...prevValues, [key]: { ...prevValues[key], ...value } };
    setDayWiseDetails(prevValues);
  };

  const getPlanDetails = async () => {
    setLoader(true);
    const res = await getBeatPlanDetails(plan_id);

    if (res && res.status === 200) {
      const data = res.data.data;
      const beatsList = await fetchBeatList(data.beat_plan_info.staff_id);
      setDayWiseDetails(formatDataForEdit(data, beatsList));
      setPlannedDates({
        start_date: data.beat_plan_info.start_date,
        end_date: data.beat_plan_info.end_date,
      });
      setSelectedStaff({
        id: data.beat_plan_info.staff_id,
        user_id: data.beat_plan_info.user_id,
        name:
          authDetails?.staff_id === data.beat_plan_info.staff_id
            ? "Self"
            : data.beat_plan_info.user_name,
      });
    }
    setLoader(false);
  };

  const handleSubmit = async () => {
    if (!plannedDates.start_date)
      return setErrors({ ...errors, start_date: true });
    else if (!selectedStaff.id) {
      document.querySelector("#staff-selector")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return setErrors({ ...errors, staff: true });
    }
    setLoader(true);
    const beat_route_day_plan = formatDataForPost(dayWiseDetails);

    const res = await createBeatPlan({
      ...(plan_id && { id: plan_id }),
      start_date: beat_route_day_plan[0].date,
      end_date: beat_route_day_plan[beat_route_day_plan.length - 1].date,
      beat_route_day_plan,
      user_id: selectedStaff.user_id,
    });
    if (res && res.status === 200) {
      setDayWiseDetails({});
      navigate(-1);
    }
    setLoader(false);
  };

  useEffect(() => {
    plan_id && getPlanDetails();
  }, [plan_id]);

  useEffect(() => {
    fetchBeatList(selectedStaff?.id);
  }, []);

  return (
    <AdminLayout
      title={
        <div className={styles.flex}>
          <img
            src={ArrowLeft}
            alt="back"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            data-testid="addBeatPlan-left_arrow_button"
          />
          {plan_id ? "Update Beat Plan " : "Create Beat Plan "}
        </div>
      }
      styles={{
        content: {
          paddingTop: 0,
        },
      }}
    >
      <Spin spinning={loader}>
        <div className={styles.flex_col} style={{ gap: "1.5em" }}>
          <div
            className={`${styles.linear_background} ${styles.form_field_background}`}
            style={{ padding: 0 }}
          >
            <div style={{ padding: "1.5em" }}>
              <div className={styles.card_title}>Select Date</div>
              <br />
              <RangePicker
                data-testid="addBeatPlan-rangePicker"
                {...(errors?.start_date &&
                  !plannedDates.start_date && {
                    status: "error",
                  })}
                value={
                  plannedDates.start_date
                    ? [
                        dayjs(plannedDates.start_date),
                        dayjs(plannedDates.end_date),
                      ]
                    : []
                }
                allowClear={false}
                onCalendarChange={(d, date) => {
                  if (!date[0] || !date[1]) return;
                  if (errors?.start_date)
                    setErrors({ ...errors, start_date: false });

                  setPlannedDates({ start_date: date[0], end_date: date[1] });
                  setDayWiseDetails(
                    generateDayWiseList(date[0], date[1], dayWiseDetails)
                  );
                }}
                disabledDate={(current, { from }) => {
                  from = from || plannedDates.start_date;
                  const today = moment().startOf("day");
                  if (from) {
                    return (
                      Math.abs(current.diff(from, "days")) >= 31 ||
                      current < today
                    );
                  }
                  return current && current < today;
                }}
                disabled={[isPlanStarted, false]}
                style={{ width: "100%" }}
              />
            </div>
            {(isAdmin || authDetails?.hierarchy) && (
              <div
                className={styles.space_between}
                style={{
                  borderTop: "2px solid #DDDDDD",
                  padding: "1.5em",
                  color: "#727176",
                }}
              >
                <div
                  style={{ fontWeight: 600 }}
                  data-testid="addBeatPlan-assigned_staff"
                >
                  Creating plan for
                  <span style={{ color: "red" }}> *</span>
                  <span style={{ color: "#000", textTransform: "capitalize" }}>
                    {" "}
                    {selectedStaff?.name}
                  </span>
                </div>
                {!plan_id && (
                  <div
                    data-testid="addBeatPlan-assign_staff_button"
                    id="staff-selector"
                    className={`button_secondary ${styles.flex}`}
                    style={{
                      gap: 5,
                      ...(errors?.staff &&
                        !selectedStaff.id && { border: "2px solid red" }),
                    }}
                    onClick={() => {
                      if (selectedStaff.id && plannedDates.start_date) {
                        return setWarningForStaffChange(true);
                      }
                      setOpenChooseStaffWindow(true);
                    }}
                  >
                    <img src={staffIcon} alt="staff" width={20} />
                    Assign Staff
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            key={selectedStaff.id}
            className={styles.flex_col}
            style={{ gap: "1.5em" }}
          >
            {Object.keys(dayWiseDetails)?.map((date) => (
              <FieldAction
                fieldValues={dayWiseDetails[date]}
                onChange={(v) => updateValues(date, v)}
                errors={errors?.dayWiseDetails?.[date] || {}}
                type={plan_id ? "EDIT" : "ADD"}
                isStaffSelected={selectedStaff.id}
                selectBeatParams={{
                  optionList: beatList,
                  filterOption: (v, option) =>
                    option?.children.props?.children
                      ?.toLowerCase()
                      ?.includes(v.toLowerCase()),
                }}
              />
            ))}
          </div>

          <div className={styles.flex} style={{ justifyContent: "flex-end" }}>
            <div
              className="button_secondary"
              onClick={() => navigate(-1)}
              data-testid="addBeatPlan-cancel_button"
            >
              Cancel
            </div>
            <button
              type="submit"
              className="button_primary"
              onClick={handleSubmit}
              data-testid="addBeatPlan-save_button"
            >
              Save
            </button>
          </div>
        </div>
        <SelectStaff
          key={`choose-staff-${openChooseStaffWindow}`}
          open={openChooseStaffWindow}
          onSubmit={(v) => {
            if (v.id !== selectedStaff.id) {
              setDayWiseDetails({
                ...generateDayWiseList(
                  plannedDates.start_date,
                  plannedDates.end_date
                ),
              });
            }
            if (errors?.staff) setErrors({ ...errors, staff: false });
            setSelectedStaff(v);
            fetchBeatList(v?.id);
          }}
          value={selectedStaff}
          {...{ setOpenChooseStaffWindow }}
        />
        <GeneralModal
          open={warningForStaffChange}
          closable={false}
          title="Reassigning staff will reset the plan form."
          cancelText="Cancel"
          okText="Proceed"
          onCancel={() => setWarningForStaffChange(false)}
          onOk={() => {
            setWarningForStaffChange(false);
            setOpenChooseStaffWindow(true);
          }}
        />
      </Spin>
    </AdminLayout>
  );
}

const generateDayWiseList = (startDate, endDate, prevData = {}) => {
  let dates = {};
  let current = moment(startDate);

  while (current <= moment(endDate)) {
    const date = current.format("YYYY-MM-DD");
    dates = {
      ...dates,
      [date]: { date, ...prevData?.[date] },
    };
    current = current.add(1, "days");
  }

  return dates;
};

const formatDataForEdit = (data, beatList) => {
  let obj = {};
  data.days_list.map((ele) => {
    let deletedBeats = [];
    const beat_ids =
      ele.module_type === "BEAT"
        ? ele.beat_ids_info.map((ele) => {
            const idx = beatList.findIndex((item) => item.id === ele.id);
            if (idx === -1) {
              deletedBeats.push(ele);
            }
            return ele.id;
          })
        : [];

    obj = {
      ...obj,
      [ele.date]: {
        date: ele.date,
        module_type: ele.module_type || "BEAT",
        module_name:
          ele.module_type === "HOLIDAY"
            ? ele.purpose
            : ele.module_type === "LOCATION"
            ? ele.module_name
            : "",
        beat_ids,
        night_stay: ele.module_type === "HOLIDAY" ? "" : ele.night_stay,
        purpose: ele.module_type === "HOLIDAY" ? "" : ele.purpose,
        deleted_beats_info: deletedBeats,
      },
    };
  });
  return obj;
};

const formatDataForPost = (planList) => {
  const beat_plan = [];
  Object.values(planList).map((ele) => {
    let moduleInfo = {};

    if (ele?.module_type === "HOLIDAY" || ele?.module_type === "LOCATION") {
      moduleInfo = {
        module_type: ele.module_type,
        ...(ele.module_type === "LOCATION"
          ? {
              module_name: ele?.module_name || "",
              purpose: ele?.purpose || "",
              night_stay: ele?.night_stay || "",
            }
          : {
              purpose: ele?.module_name || "",
            }),
      };
    } else {
      moduleInfo = {
        module_type: ele?.beat_ids?.length ? ele.module_type || "BEAT" : "",
        beat_ids: ele.beat_ids,
        purpose: ele?.purpose || "",
        night_stay: ele?.night_stay || "",
      };
    }

    beat_plan.push({
      date: ele.date,
      ...moduleInfo,
    });
  });
  return beat_plan;
};
