import { notification } from "antd";
import axios from "axios";
import { BASE_URL_V1, org_id } from "../../config.js";
import SessionExpireError from "../../helpers/sessionExpireError.js";
import { headers } from "../../services/api-service.js";

const baseUrl = `${BASE_URL_V1}/organization/${org_id}/product/`;

export const fetchProductList = async (params = {}, type) => {
  const url = type === "ES" ? baseUrl + "es/" : baseUrl;
  return await axios
    .get(url, { headers, params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      SessionExpireError(error.response);
      return {};
    });
};

export const productDetail = async (id) => {
  const url = baseUrl + `${id}/`;
  return await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const variantDetail = async (primary_product_id) => {
  const url = baseUrl + `variant/${primary_product_id}/`;
  return await axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const addProduct = async (formData, id) => {
  const url = id ? baseUrl + `${id}/` : baseUrl;
  const data = formData;

  return axios
    .post(url, data, { headers })
    .then((response) => {
      if (response.status === 200) {
        notification.success({
          message: `${response.data.message}`,
        });
      }
      return response;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: `${err.response.data.message}`,
      });
      return err.response;
    });
};

export const deleteProduct = async (data) => {
  const url = baseUrl + `delete/${data.id}/`;

  return axios
    .post(url, data, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      notification.warning({ message: error?.response?.data?.message });
      SessionExpireError(error.response);
    });
};
