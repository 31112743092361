import { notification } from "antd";
import axios from "axios";
import Cookies from "universal-cookie";
import { BASE_URL_V2, org_id } from "../../config.js";
import SessionExpireError from "../../helpers/sessionExpireError.js";
import { lead } from "../constant";
import { headers } from "../../services/api-service.js";

const cookies = new Cookies();

export const getLeadCategoryList = async (params) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/leadcategory/`;

  return await axios
    .get(url, { headers, params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const createLeadCategory = async (data, id) => {
  const url = id
    ? `${BASE_URL_V2}/organization/${org_id}/leadcategory/${id}/`
    : `${BASE_URL_V2}/organization/${org_id}/leadcategory/`;

  return await axios
    .post(url, data, { headers })
    .then((response) => {
      notification.success({ message: response?.data?.message });
      return response;
    })
    .catch((error) => {
      SessionExpireError(error.response);
      notification.warning({ message: error?.response?.data?.message });
    });
};

export const deleteLeadsCategory = async (id) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/leadcategory/${id}/delete/`;

  return await axios
    .post(url, {}, { headers })
    .then((response) => {
      notification.success({ message: response?.data?.message });
      return response;
    })
    .catch((error) => {
      SessionExpireError(error.response);
      notification.warning({ message: error?.response?.data?.message });
    });
};

export const fetchLeadList = async (params) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/leadform/`;

  return axios
    .get(url, { headers, params })
    .then((res) => {
      return res.data.data || [];
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const singleLeadDataAction = (id) => (dispatch) => {
  if (!id) return;
  const url = `${BASE_URL_V2}/organization/${org_id}/leadform/${id}/`;
  axios
    .get(url, { headers })
    .then((response) => {
      cookies.set("leadData", response.data.data, { path: "/" });
      dispatch({
        type: lead.SINGLE_LEAD_DATA,
        payload: response,
      });
      setTimeout(() => {
        dispatch({
          type: lead.SINGLE_LEAD_DATA,
          payload: "",
        });
      }, 400);
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const searchLeadAction = (pageCount, name) => (dispatch) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/leadform/`;
  const params = { page_no: pageCount, name: name };
  axios
    .get(url, { headers, params })
    .then((response) => {
      dispatch({
        type: lead.SEARCH_LEAD,
        payload: response,
      });
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const createLead = (formData) => (dispatch) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/leadform/`;
  const data = formData;
  axios
    .post(url, data, { headers })
    .then((response) => {
      if (response.status == 200) {
        notification.success({
          message: `${response.data.message}`,
        });
      }
      dispatch({
        type: lead.CREATE_LEAD,
        payload: response,
      });
      setTimeout(() => {
        dispatch({
          type: lead.CREATE_LEAD,
          payload: "",
        });
      }, 2000);
    })
    .catch((error) => {
      SessionExpireError(error.response);
      notification.warning({
        message: `${error.response.data.message}`,
      });
    });
};

export const updateLead = async (id, formData) => {
  let url;
  if (id) {
    url = `${BASE_URL_V2}/organization/${org_id}/leadform/${id}/`;
  } else {
    url = `${BASE_URL_V2}/organization/${org_id}/leadform/`;
  }
  const data = formData;
  try {
    const response = await axios.post(url, data, { headers });

    if (response.status == 200) {
      notification.success({
        message: `${response.data.message}`,
      });
    }
    return response;
  } catch (error) {
    SessionExpireError(error.response);
    notification.warning({
      message: `${error.response.data.message}`,
    });
  }
};

export const deleteLead = async (id) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/leadform/${id}/delete/`;
  return axios
    .post(url, {}, { headers })
    .then((response) => {
      if (response.status == 200) {
        notification.success({
          message: `${response.data.message}`,
        });
        return response;
      }
    })
    .catch((error) => {
      SessionExpireError(error.response);
      notification.warning({
        message: `${error.response.data.message}`,
      });
    });
};

export const updateLeadStatus = async (values) => {
  if (values.id === undefined) return;
  const url = `${BASE_URL_V2}/organization/${org_id}/leadform/${values.id}/approval/`;

  return axios
    .post(url, values, { headers })
    .then((response) => {
      if (response.status == 200) {
        notification.success({
          message: `${response.data.message}`,
        });
      }
      return response;
    })
    .catch((error) => {
      SessionExpireError(error.response);
      notification.warning({
        message: `${error.response.data.message}`,
      });
    });
};

export const mobileNumberCheck = async (mobile) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/leadform/mobile/`;
  const params = { mobile };
  try {
    const response = await axios.get(url, { headers, params });
    return response.data;
  } catch (error) {
    SessionExpireError(error.response);
    return error.response.data;
  }
};

export const leadReport = async (data) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/leadform/reports/`;

  try {
    const response = await axios.post(url, data, { headers });
    if (response.status === 200) {
      notification.success({
        message: `${response.data.message}`,
      });
    }
    return response;
  } catch (error) {
    SessionExpireError(error.response);
    notification.warning({
      message: `${error.response.data.message}`,
    });
  }
};

//  ------------------  //

export const fetchLeadDetails = async (id) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/leadform/${id}/`;
  return axios
    .get(url, { headers })
    .then((response) => response)
    .catch((error) => {
      SessionExpireError(error.response);
    });
};
