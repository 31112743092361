import { BASE_URL_V2, org_id } from "../../config.js";
import CustomerActivityCard from "../../components/viewDrawer/distributor-details/customerActivityCard.js";
import InfiniteScrollWrapper from "../../components/infinite-scroll-wrapper/infiniteScrollWrapper.js";

const ActivityView = ({ customerId }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: "1em" }}
      className="no-scrollbar"
    >
      <InfiniteScrollWrapper
        {...{
          apiUrl: `${BASE_URL_V2}/organization/${org_id}/activitylog/customer/${customerId}/?is_all_true=true`,

          height: 500,
        }}
      >
        {(data, key) => <CustomerActivityCard {...{ key, data }} />}
      </InfiniteScrollWrapper>
    </div>
  );
};

export default ActivityView;
