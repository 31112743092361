export default function ExpenseIcon({ fill = "#727176", size = "24" }) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.825 26.735H7.5C6.32125 26.735 5.7325 26.735 5.36625 26.3687C5 26.0037 5 25.415 5 24.2362V22.8462C5 22.1987 5 21.875 5.16625 21.585C5.3325 21.2962 5.58375 21.15 6.08625 20.8575C9.39375 18.9337 14.0888 17.8512 17.2238 19.72C17.4338 19.8462 17.6237 19.9987 17.7863 20.18C17.9541 20.364 18.0834 20.5797 18.1666 20.8145C18.2497 21.0493 18.285 21.2983 18.2703 21.547C18.2556 21.7956 18.1913 22.0388 18.0811 22.2621C17.9709 22.4855 17.8171 22.6845 17.6287 22.8475C17.4785 22.9903 17.2951 23.0934 17.095 23.1475C17.245 23.13 17.3888 23.11 17.5262 23.0887C18.665 22.9075 19.6212 22.2975 20.4962 21.6362L22.7563 19.93C23.1594 19.6367 23.6452 19.4787 24.1438 19.4787C24.6423 19.4787 25.1281 19.6367 25.5312 19.93C26.2475 20.4712 26.4675 21.3625 26.0138 22.09C25.485 22.9375 24.74 24.0212 24.025 24.6837C23.3087 25.3462 22.2425 25.9387 21.3725 26.3587C20.4075 26.825 19.3425 27.0925 18.2588 27.2687C16.0613 27.6237 13.7713 27.5687 11.5963 27.1212C10.3554 26.8661 9.09185 26.7358 7.825 26.735ZM8.2325 3.23248C7.77375 3.69123 7.6025 4.32373 7.53875 5.31248C8.27149 5.30244 8.97138 5.00682 9.48943 4.48853C10.0075 3.97024 10.3028 3.27022 10.3125 2.53748C9.325 2.60248 8.69125 2.77373 8.2325 3.23248ZM21.7675 3.23248C21.3088 2.77373 20.6763 2.60248 19.6875 2.53873C19.6975 3.27146 19.9932 3.97135 20.5114 4.48941C21.0297 5.00746 21.7298 5.30277 22.4625 5.31248C22.3975 4.32498 22.2262 3.69123 21.7675 3.23248ZM21.7675 11.7675C21.3088 12.2262 20.6763 12.3975 19.6875 12.4612C19.6975 11.7285 19.9932 11.0286 20.5114 10.5105C21.0297 9.99249 21.7298 9.69718 22.4625 9.68748C22.3975 10.675 22.2262 11.3087 21.7675 11.7675ZM8.2325 11.7675C8.69125 12.2262 9.32375 12.3975 10.3125 12.4612C10.3025 11.7285 10.0068 11.0286 9.48855 10.5105C8.97026 9.99249 8.27024 9.69718 7.5375 9.68748C7.6025 10.675 7.77375 11.3087 8.2325 11.7675Z"
        {...{ fill }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 7.1875C8.7432 7.1875 9.93549 6.69364 10.8146 5.81456C11.6936 4.93549 12.1875 3.7432 12.1875 2.5H17.8125C17.8125 3.7432 18.3064 4.93549 19.1854 5.81456C20.0645 6.69364 21.2568 7.1875 22.5 7.1875V7.8125C21.2568 7.8125 20.0645 8.30636 19.1854 9.18544C18.3064 10.0645 17.8125 11.2568 17.8125 12.5H12.1875C12.1875 11.2568 11.6936 10.0645 10.8146 9.18544C9.93549 8.30636 8.7432 7.8125 7.5 7.8125V7.1875ZM15 8.75C15.3315 8.75 15.6495 8.6183 15.8839 8.38388C16.1183 8.14946 16.25 7.83152 16.25 7.5C16.25 7.16848 16.1183 6.85054 15.8839 6.61612C15.6495 6.3817 15.3315 6.25 15 6.25C14.6685 6.25 14.3505 6.3817 14.1161 6.61612C13.8817 6.85054 13.75 7.16848 13.75 7.5C13.75 7.83152 13.8817 8.14946 14.1161 8.38388C14.3505 8.6183 14.6685 8.75 15 8.75Z"
        {...{ fill }}
      />
    </svg>
  );
}
