export const cartConstants = {
  key: "cart-v1",
  defaultCartDetails: {
    distributor: {
      id: "",
      name: "",
    },
    discountList: [],
    otherChargesList: [],
    orderId: "",
    orderDetail: {},
  },
  defaultDiscountDetails: {
    productDiscount: 0,
    discount_value: 0,
    discount_details: {},
  },
};

var cartstates = {
  eventListner: () => {},
};

const setEventListner = (callback) => {
  cartstates.eventListner = callback;
};

const fetchCart = () => {
  return JSON.parse(localStorage.getItem(cartConstants.key) || "[]");
};

const fetchCartItem = (id) => {
  let cartData = fetchCart();
  let index = cartData.findIndex((ele) => ele.id === id);
  if (index !== -1) {
    return cartData[index];
  } else return {};
};

const updateCartItem = (productDetails, callback) => {
  let cartData = fetchCart();
  let index = cartData.findIndex((ele) => ele.id === productDetails["id"]);
  if (productDetails["qty"] !== -1) {
    if (index === -1) {
      // add product to cart
      cartData.push({
        ...cartConstants.defaultDiscountDetails,
        ...productDetails,
      });
    } else {
      // update product in cart
      cartData[index] = {
        ...cartData[index],
        ...productDetails,
      };
    }
  } else {
    // remove product from cart
    if (index !== -1) {
      cartData.splice(index, 1);
    }
  }
  localStorage.setItem(cartConstants.key, JSON.stringify(cartData));
  if (callback) {
    callback(fetchCartItem(productDetails["id"]));
  }
  if (cartstates.eventListner) {
    let cartCount = productDetails["qty"]
      ? cartData.length
      : cartData.length - 1;
    cartstates.eventListner(cartData, cartCount);
  }
};

const clearCart = () => {
  localStorage.setItem("payment-details", "{}");
  localStorage.setItem("cart-details", "{}");
  localStorage.setItem(cartConstants.key, "[]");
  if (cartstates.eventListner) {
    cartstates.eventListner([], 0);
  }
};

const initCart = (distributor, cartDetails = {}) => {
  clearCart();
  let { id, name, parent_name, parent_id, payment_term } = distributor;
  localStorage.setItem(
    "cart-details",
    JSON.stringify({
      distributor: { id, name, parent_name, parent_id, payment_term },
      discountList: [],
      otherChargesList: [],
      orderId: "",
      orderDetail: {},
      ...cartDetails,
    })
  );
};
const fetchCartDetails = () => {
  return JSON.parse(
    localStorage.getItem("cart-details") ||
      JSON.stringify(cartConstants.defaultCartDetails)
  );
};
const updateCartDetails = (cartDetails = {}) => {
  localStorage.setItem(
    "cart-details",
    JSON.stringify({
      ...fetchCartDetails(),
      ...cartDetails,
    })
  );
};

const cartService = {
  setEventListner,
  fetchCart,
  fetchCartItem,
  updateCartItem,
  clearCart,
  initCart,
  fetchCartDetails,
  updateCartDetails,
};

export default cartService;
