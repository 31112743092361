export default function PaymentIcon({ fill = "#727176", size = "24" }) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <path
        d="M27 5.71176C26.9987 5.08857 26.8177 4.49141 26.4965 4.05074C26.1753 3.61008 25.74 3.36174 25.2857 3.35999H4.71429C4.26002 3.36174 3.82473 3.61008 3.50351 4.05074C3.1823 4.49141 3.00128 5.08857 3 5.71176V9.23999H27V5.71176Z"
        {...{ fill }}
      />
      <path
        d="M3 24.5C3.00128 25.1306 3.1823 25.735 3.50351 26.1809C3.82473 26.6269 4.26002 26.8782 4.71429 26.88H25.2857C25.74 26.8782 26.1753 26.6269 26.4965 26.1809C26.8177 25.735 26.9987 25.1306 27 24.5V12.6H3V24.5ZM10.6074 20.9305H19.3926C19.5915 20.9311 19.782 21.0411 19.9226 21.2363C20.0632 21.4315 20.1424 21.6961 20.1429 21.9721C20.1424 22.2482 20.0632 22.5128 19.9226 22.708C19.782 22.9032 19.5915 23.0131 19.3926 23.0137H10.6074C10.4085 23.0131 10.218 22.9032 10.0774 22.708C9.93675 22.5128 9.85757 22.2482 9.85714 21.9721C9.85757 21.6961 9.93675 21.4315 10.0774 21.2363C10.218 21.0411 10.4085 20.9311 10.6074 20.9305ZM6.75045 20.9305H7.39583C7.59468 20.9311 7.78526 21.0411 7.92587 21.2363C8.06648 21.4315 8.14566 21.6961 8.14608 21.9721C8.14566 22.2482 8.06648 22.5128 7.92587 22.708C7.78526 22.9032 7.59468 23.0131 7.39583 23.0137H6.75126C6.55241 23.0131 6.36183 22.9032 6.22122 22.708C6.08062 22.5128 6.00143 22.2482 6.00101 21.9721C6.00143 21.6961 6.08062 21.4315 6.22122 21.2363C6.36183 21.0411 6.55161 20.9311 6.75045 20.9305Z"
        {...{ fill }}
      />
    </svg>
  );
}
