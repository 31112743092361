import { lazy } from "react";
import AddAddress from "../views/cart/addAddress";
import ActivityDetailLogs from "../views/activities/ActivityDetailLogs";
import BulkCustomerBeatMap from "../views/bulkProduct/bulkCustomerBeatMap";
import AddBeatPlan from "../views/beat-plan/AddBeatPlan/AddBeatPlan";
import AddEditPricingGroup from "../views/settings/pricing-group/add-edit/AddEditPricingGroup";
import ManageProductsPricing from "../views/settings/pricing-group/add-edit/ManageProductsPricing";
import routesLink from "../refactor/constants/routes.json";

// Convert all imports to lazy loading
const Dashboard = lazy(() => import("../refactor/pages/Dashboard"));
const Product = lazy(() => import("../views/product"));
const AddProduct = lazy(() => import("../views/product/form/addProduct"));
const BulkProduct = lazy(() => import("../views/bulkProduct/bulkProduct"));
const BulkStaff = lazy(() => import("../views/bulkProduct/bulkStaff"));
const BulkStaffCustomerMap = lazy(() =>
  import("../views/bulkProduct/bulkStaffCustomerMap")
);
const BulkCustomer = lazy(() => import("../views/bulkProduct/bulkCustomer"));
const BulkBrand = lazy(() => import("../views/bulkProduct/bulkBrand"));
const ProductGallery = lazy(() => import("../views/Gallery/index"));
const Staff = lazy(() => import("../views/staff"));
const Distributor = lazy(() => import("../views/distributor"));
const AddCustomer = lazy(() => import("../views/distributor/addCustomer"));
const Order = lazy(() => import("../views/order"));
const OrderDetails = lazy(() => import("../views/order/orderDetails"));
const Payment = lazy(() => import("../views/payment"));
const ViewLeadComponent = lazy(() =>
  import("../views/leadManagement/viewLead")
);

const Report = lazy(() => import("../views/report"));
const CustomReport = lazy(() => import("../views/custom-report"));
const ProductCardView = lazy(() => import("../views/product/productCardView"));
const Cart = lazy(() => import("../views/cart"));
const UpdateOrder = lazy(() => import("../views/cart/updateOrder"));
const Address = lazy(() => import("../views/cart/address"));
const RolesPermission = lazy(() => import("../views/roles-permission"));
const LeadManagement = lazy(() => import("../views/leadManagement"));
const AddLeadForm = lazy(() => import("../views/leadManagement/addLeadForm"));
const ReimbursementTracker = lazy(() =>
  import("../views/reimbursement-tracker")
);
const AddExpenseHeadComponent = lazy(() =>
  import("../views/reimbursement-tracker/addExpenseHead")
);
const AddExpenseComponent = lazy(() =>
  import("../views/reimbursement-tracker/addExpense")
);
const ApprovalReimbursementTracker = lazy(() =>
  import("../views/reimbursement-tracker/approvalRequest")
);
const TeamActivities = lazy(() => import("../views/activities"));
const CustomerActivities = lazy(() =>
  import("../views/distributor/customerActivity")
);
const BulkUpload = lazy(() =>
  import("../views/settings/pricingGroupBulk/bulkUpload")
);
const Goal = lazy(() => import("../views/goal"));
const IndividualTarget = lazy(() => import("../views/goal/individualTarget"));
const CreateTarget = lazy(() => import("../views/goal/createTarget"));
const AssignTarget = lazy(() => import("../views/goal/assignTarget"));
const TeamTargets = lazy(() => import("../views/goal/teamTargets"));
const BeatListNew = lazy(() => import("../views/beat-list"));
const CreateBeat = lazy(() => import("../views/beat-list/createBeat"));
const StaffPreferences = lazy(() =>
  import("../views/preferences/staffPreference")
);
const StoreSettings = lazy(() => import("../views/store-settings"));
const BeatPlanList = lazy(() => import("../views/beat-plan"));

const AdminSetting = lazy(() => import("../views/settings/admin"));
const StaffForm = lazy(() => import("../views/staff/form/staffForm"));
const ViewStaffDetails = lazy(() => import("../views/staff/viewStaffDetails"));
// const IndividualBeatList = lazy(() =>
//   import("../views/staff/individualBeatList")
// );
const CustomFormCreate = lazy(() =>
  import("../views/custom-forms/CustomFormCreate")
);
const PaymentsReceived = lazy(() => import("../views/razorpay-payments"));
const SettingsComponent = lazy(() => import("../views/settings"));
const CheckoutOrder = lazy(() => import("../views/cart/checkoutOrder"));
const ShippedOrder = lazy(() => import("../components/shippedOrder"));
const CreateBrand = lazy(() => import("../views/settings/brand/createBrand"));
const StaffRolesPermission = lazy(() => import("../views/roles-permission"));
const CreateNewStaffRole = lazy(() =>
  import("../views/roles-permission/createRole")
);

export const links = {
  PRICING_GROUP_BULK_UPLOAD: "/pricing-group/bulk-upload",
};

const routes = [
  //for dashboard routes
  { path: routesLink.HOME, element: <Dashboard /> },

  // for Product routes
  { path: routesLink.PRODUCT.LIST, element: <Product /> },
  { path: routesLink.PRODUCT.UPDATE, element: <AddProduct /> },
  { path: routesLink.PRODUCT.BULK_UPLOAD, element: <BulkProduct /> },

  // for Beat routes
  { path: routesLink.BEAT.LIST, element: <BeatListNew /> },
  { path: routesLink.BEAT.PLAN, element: <BeatPlanList /> },
  { path: routesLink.BEAT.UPDATE, element: <CreateBeat /> },
  { path: routesLink.BEAT.PLAN_UPDATE, element: <AddBeatPlan /> },
  { path: routesLink.BEAT.BULK_UPLOAD, element: <BulkCustomerBeatMap /> },

  // for Target routes
  { path: routesLink.TARGET.SELF, element: <IndividualTarget /> },
  { path: routesLink.TARGET.TEAM, element: <TeamTargets /> },
  { path: routesLink.TARGET.UPDATE, element: <CreateTarget /> },
  { path: routesLink.TARGET.ASSIGN, element: <AssignTarget /> },
  { path: routesLink.TARGET.TEMPLATE, element: <Goal /> },

  // for staff routes
  { path: routesLink.STAFF.LIST, element: <Staff /> },
  { path: routesLink.STAFF.UPDATE, element: <StaffForm /> },
  { path: routesLink.STAFF.DETAILS, element: <ViewStaffDetails /> },
  { path: routesLink.STAFF.BULK_UPLOAD, element: <BulkStaff /> },
  {
    path: routesLink.STAFF.MAPPING_BULK_UPLOAD,
    element: <BulkStaffCustomerMap />,
  },

  // for Customer routes
  { path: routesLink.CUSTOMER.LIST, element: <Distributor /> },
  { path: routesLink.CUSTOMER.UPDATE, element: <AddCustomer /> },
  { path: routesLink.CUSTOMER.BULK_UPLOAD, element: <BulkCustomer /> },

  // for Order routes
  { path: routesLink.ORDER.LIST, element: <Order /> },
  { path: routesLink.ORDER.DETAILS, element: <OrderDetails /> },
  { path: routesLink.ORDER.UPDATE, element: <UpdateOrder /> },
  { path: routesLink.ORDER.CHECKOUT, element: <CheckoutOrder /> },
  { path: routesLink.ORDER.DISPATCH, element: <ShippedOrder /> },
  { path: routesLink.ORDER.PRODUCTS, element: <ProductCardView /> },
  { path: routesLink.ORDER.CART, element: <Cart /> },
  { path: routesLink.ORDER.ADDRESS, element: <Address /> },
  { path: routesLink.ORDER.UPDATE_ADDRESS, element: <AddAddress /> },

  // for Lead routes
  { path: routesLink.LEAD.LIST, element: <LeadManagement /> },
  { path: routesLink.LEAD.UPDATE, element: <AddLeadForm /> },
  { path: routesLink.LEAD.DETAILS, element: <ViewLeadComponent /> },

  // for Payment routes
  { path: routesLink.PAYMENT.LIST, element: <Payment /> },
  { path: routesLink.PAYMENT.CONFORMATION, element: <PaymentsReceived /> },

  // for Picture gallery routes
  { path: routesLink.PICTURE_GALLERY.LIST, element: <ProductGallery /> },

  // for Storefront routes
  { path: routesLink.STOREFRONT, element: <StoreSettings /> },

  // for Report routes
  { path: routesLink.REPORTS.LIST, element: <Report /> },

  // for Staff Roles routes
  { path: routesLink.STAFF_ROLES.LIST, element: <StaffRolesPermission /> },
  { path: routesLink.STAFF_ROLES.UPDATE, element: <CreateNewStaffRole /> },

  // for activity routes
  { path: routesLink.ACTIVITIES.LIST, element: <TeamActivities /> },
  { path: "/team-activity/details/:id", element: <ActivityDetailLogs /> },

  // for Expencies routes
  { path: routesLink.EXPENSE.SELF, element: <ReimbursementTracker /> },
  {
    path: routesLink.EXPENSE.FOR_APPROVAL,
    element: <ApprovalReimbursementTracker />,
  },
  { path: routesLink.EXPENSE.UPDATE, element: <AddExpenseHeadComponent /> },
  {
    path: routesLink.EXPENSE.UPDATE_SUB_LIST,
    element: <AddExpenseComponent />,
  },

  // for Setting routes
  { path: routesLink.SETTINGS.LIST, element: <SettingsComponent /> },
  {
    path: routesLink.SETTINGS.UPDATE_PRICING_GROUP,
    element: <AddEditPricingGroup />,
  },
  {
    path: routesLink.SETTINGS.UPDATE_PRICING_GROUP_PRODUCT,
    element: <ManageProductsPricing />,
  },
  {
    path: routesLink.SETTINGS.PRICING_GROUP_BULK_UPLOAD,
    element: <BulkUpload />,
  },
  { path: routesLink.SETTINGS.UPDATE_BRAND, element: <CreateBrand /> },
  { path: routesLink.SETTINGS.BRAND_BULK_UPLOAD, element: <BulkBrand /> },
  {
    path: routesLink.SETTINGS.UPDATE_CUSTOM_FORM,
    element: <CustomFormCreate />,
  },
  { path: routesLink.SETTINGS.UPDATE_CUSTOM_REPORT, element: <CustomReport /> },

  { path: "/profile-settings", element: <StaffPreferences /> },
  { path: "/admin-setting", element: <AdminSetting /> },
  { path: "/customer-activity", element: <CustomerActivities /> },
];

export default routes;
