export default function OverviewIcon({ fill = "#727176", size = "24" }) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
    >
      <path
        d="M3.75 26V4H13.5278V26H3.75ZM15.9722 12.5556V4H25.75V12.5556H15.9722ZM25.75 26H15.9722V15H25.75V26Z"
        {...{ fill }}
      />
    </svg>
  );
}
