import React, { Suspense, useContext } from "react";
import { ConfigProvider, Layout } from "antd";
import { Route, Routes } from "react-router-dom";
import TopNavbar from "../components/topNavbar";
import Context from "../context/Context";
import routes from "./routesConstant";
import SideNavBar from "../refactor/components/SideNavBar";

const Depreciate = ({ children }) => {
  return (
    <div className="depreciate">
      <ConfigProvider>{children}</ConfigProvider>
    </div>
  );
};

export const Refactor = ({ children }) => {
  const styles = getComputedStyle(document.body);
  const colorTheme1 = styles.getPropertyValue("--color-theme-1");
  const colorTheme2 = styles.getPropertyValue("--color-theme-2");
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colorTheme1,
          colorTheme1,
          colorTheme2,
        },
        components: {
          Tabs: {
            itemActiveColor: colorTheme1,
            itemHoverColor: colorTheme1,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

const { Content } = Layout;

const DashboardRoutes = () => {
  const context = useContext(Context);
  const { setIsProfileDropdownOpen, setIsNotificationOpen, setReminderIsOpen } =
    context;

  return (
    <Layout className="app-layout">
      <TopNavbar />
      <Layout>
        <SideNavBar />
        <Content
          className="app-content"
          id="dashboard_layout"
          onClick={() => {
            setIsProfileDropdownOpen(false);
            setIsNotificationOpen(false);
            setReminderIsOpen(false);
          }}
        >
          <Suspense fallback={<div></div>}>
            <Routes>
              {routes.map((route, index) =>
                ["/"].includes(route.path) ? (
                  <Route
                    key={index}
                    path={route.path}
                    element={<Refactor>{route.element}</Refactor>}
                  />
                ) : (
                  <Route
                    key={index}
                    path={route.path}
                    element={<Depreciate>{route.element}</Depreciate>}
                  />
                )
              )}
            </Routes>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardRoutes;
