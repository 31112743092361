export default function StorefrontIcon({ fill = "#727176", size = "24" }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_8405_5772"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8405_5772)">
        <path
          d="M4.21169 5.74995V4.25H19.7886V5.74995H4.21169ZM4.25017 19.75V13.75H3.13477V12.25L4.21169 7.25H19.7886L20.8655 12.25V13.75H19.7501V19.75H18.2502V13.75H13.7501V19.75H4.25017ZM5.75012 18.25H12.2502V13.75H5.75012V18.25Z"
          {...{ fill }}
        />
      </g>
    </svg>
  );
}
