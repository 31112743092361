import { useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  Divider,
  Row,
  Space,
  theme,
} from "antd";
import packageInfo from "../../../../package.json";
import LoginForm from "./LoginForm";
import Cookies from "universal-cookie";
import authService from "../../../services/auth-service";
import ContactUsForm from "./ContactUsForm";

const constants = {
  externalLinks: {
    terms: "https://rupyz.com/term-condition",
    downloadApp: "https://bit.ly/rupyz-android",
    privacyPolicy: "https://rupyz.com/privacy-policy",
    bookDemo: "https://rupyz.zohobookings.in/portal-embed#/demo",
  },
};

const Login = () => {
  const cookies = new Cookies();

  const [authData, setAuthData] = useState({});
  const [selectedOrg, setSelectedOrg] = useState();
  const [viewContactForm, setViewContactForm] = useState(false);

  const { token } = theme.useToken();

  const links = [
    {
      name: "Terms",
      link: constants.externalLinks.terms,
    },
    {
      name: "Privacy Policy",
      link: constants.externalLinks.privacyPolicy,
    },
    {
      name: "Contact Us",
      action: () => setViewContactForm(true),
    },
  ];

  const handleOrganizationSelect = () => {
    cookies.set("rupyzToken", `Bearer ${authData.credentials.access_token}`, {
      path: "/",
    });
    localStorage.setItem("rupyzOrgList", JSON.stringify(authData.org_ids));
    cookies.set("rupyzAccessType", authData.access_type, { path: "/" });
    cookies.set(
      "rupyzUserName",
      authData?.org_ids?.[selectedOrg || 0]?.legal_name || authData?.staff_name,
      { path: "/" }
    );

    authService.setUserDetails({
      ...authData,
      org_id: authData?.org_ids?.[selectedOrg || 0]?.id || authData?.org_id,
    });
    window.location.reload();
  };

  if (authData?.org_ids?.length) {
    return (
      <Row className="app-layout refactor">
        <img style={styles.logo} src={require("../../assets/logo-theme.png")} />
        <Col flex={1} className="center">
          <ConfigProvider
            theme={{
              token: { colorSplit: "#fff" },
              components: { Button: { colorPrimary: token.colorTheme2 } },
            }}
          >
            <Card
              data-testid="orgSelect-card"
              style={{ width: 500, backgroundColor: "var(--color-theme-1)" }}
            >
              <Space direction="vertical" size={20} style={{ width: "100%" }}>
                <h4 style={{ fontWeight: "600", color: "#fff" }}>
                  Select Organization
                </h4>
                <Space
                  direction="vertical"
                  className="scrollview"
                  style={{
                    width: "100%",
                    maxHeight: 400,
                  }}
                >
                  {authData.org_ids.map((org, i) => {
                    return (
                      <Card
                        size="small"
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.1)",
                          padding: "2px 0px",
                          ...(authData.org_ids[selectedOrg]?.id === org.id && {
                            opacity: 1,
                          }),
                        }}
                        className="hover-focus clickable"
                        onClick={() => {
                          setSelectedOrg(i);
                        }}
                        data-testid="orgSelect-org_name"
                        data-value={org.legal_name}
                      >
                        <Row align={"middle"} gutter={8}>
                          <Col>
                            <Checkbox
                              data-testid="orgSelect-checkbox"
                              checked={
                                authData.org_ids[selectedOrg]?.id === org.id
                              }
                            />
                          </Col>
                          <Col>
                            <Divider type="vertical" style={{ height: 40 }} />
                          </Col>
                          <Col>
                            <p style={{ color: "#fff" }} className="focus-item">
                              {org.legal_name}
                            </p>
                            <h5
                              data-testid="orgSelect-pan_id"
                              data-value={org.pan_id}
                              style={{
                                color: "#00EEFF",
                              }}
                            >
                              ({org.pan_id})
                            </h5>
                          </Col>
                        </Row>
                      </Card>
                    );
                  })}
                </Space>
                <Col align="middle">
                  <Button
                    size="large"
                    shape="round"
                    style={{
                      width: 120,
                      backgroundColor: "var(--color-theme-2)",
                      color: "#fff",
                    }}
                    disabled={!authData.org_ids[selectedOrg]?.id}
                    onClick={handleOrganizationSelect}
                    data-testid="orgSelect-next_button"
                  >
                    NEXT
                  </Button>
                </Col>
              </Space>
            </Card>
          </ConfigProvider>
        </Col>
      </Row>
    );
  } else if (authData?.access_token_id) {
    handleOrganizationSelect();
  }

  return (
    <Row className="app-layout refactor">
      <Col
        style={{ flex: 1, backgroundColor: "#fff" }}
        align="middle"
        className="center"
      >
        <img
          style={styles.logo}
          src={require("../../assets/logo-theme.png")}
          alt="logo"
        />
        <Space direction="vertical" size={"middle"} style={{ width: 400 }}>
          <h1>Sign in</h1>
          <LoginForm onSuccess={setAuthData} />
          <div />
          <h4 className="subtitle">
            Not a Member yet?{" "}
            <a
              target="_blank"
              href={constants.externalLinks.bookDemo}
              rel="noreferrer"
            >
              Book Demo
            </a>
          </h4>
          <div />
        </Space>
        <Space direction="vertical" size="large" style={styles.footer}>
          <Space size="large">
            {links.map((obj, i) => (
              <a
                key={i}
                {...(obj?.link?.startsWith("https://")
                  ? {
                      target: "_blank",
                      href: obj.link,
                      rel: "noreferrer",
                    }
                  : {
                      onClick: () => obj.action(),
                    })}
              >
                <h4>{obj.name}</h4>
              </a>
            ))}
          </Space>
          <h5 className="subtitle">Version - {packageInfo.version}</h5>
        </Space>
      </Col>
      <Col
        align="middle"
        className="center"
        style={{ flex: 1, backgroundColor: "var(--color-theme-3)" }}
      >
        <Space direction="vertical" size={"middle"} style={{ width: 400 }}>
          <img
            src={require("../../assets/graphics/mobile-app.png")}
            alt="mobile-app"
            style={{ width: 300 }}
          />
          <a
            href={constants.externalLinks.downloadApp}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src={require("../../assets/graphics/google-play-btn.svg").default}
              alt="google-play"
              style={{ height: 40 }}
            />
          </a>
          <h2 style={{ color: "#fff" }}>
            Accelerate B2B sales & distribution Grow your Business!!
          </h2>
          <div />
        </Space>
        <h4 style={{ color: "#fff", width: 550 }}>
          Digitise & automate sales process, get real time business visibility
          with our Integrated SaaS platform
        </h4>
      </Col>
      <ContactUsForm {...{ viewContactForm, setViewContactForm }} />
    </Row>
  );
};

const styles = {
  logo: {
    position: "absolute",
    top: 30,
    left: 40,
    width: 150,
  },
  footer: {
    position: "absolute",
    bottom: 30,
  },
};

export default Login;
