import hasAccess from "../../../services/permissions";
import authService from "../../../services/auth-service";
import { preference } from "../../../services/preference-service";
import routes from "../../constants/routes.json";
// navbar icons
import LeadIcon from "../../assets/icons/navbar/LeadIcon";
import BeatIcon from "../../assets/icons/navbar/BeatIcon";
import StaffIcon from "../../assets/icons/navbar/StaffIcon";
import OrderIcon from "../../assets/icons/navbar/OrderIcon";
import TargetIcon from "../../assets/icons/navbar/TargetIcon";
import ReportIcon from "../../assets/icons/navbar/ReportIcon";
import ProductIcon from "../../assets/icons/navbar/ProductIcon";
import PaymentIcon from "../../assets/icons/navbar/PaymentIcon";
import ExpenseIcon from "../../assets/icons/navbar/ExpenseIcon";
import CustomerIcon from "../../assets/icons/navbar/CustomerIcon";
import OverviewIcon from "../../assets/icons/navbar/OverviewIcon";
import WhatsappIcon from "../../assets/icons/navbar/WhatsappIcon";
import StorefrontIcon from "../../assets/icons/navbar/StorefrontIcon";
import StaffRolesIcon from "../../assets/icons/navbar/StaffRolesIcon";
import ActivitiesIcon from "../../assets/icons/navbar/ActivitiesIcon";
import PictureGalleryIcon from "../../assets/icons/navbar/PictureGalleryIcon";
import SettingsIcon from "../../assets/icons/navbar/SettingsIcon";

export default () => {
  const isAdmin = hasAccess();
  const hasHierarchy = authService.fetchUserDetails()?.hierarchy;
  return [
    {
      key: routes.HOME,
      label: "Overview",
      icon: OverviewIcon,
      pathnames: [routes.HOME],
    },
    hasAccess("VIEW_PRODUCT") && {
      key: "PRODUCT",
      label: "Product",
      icon: ProductIcon,
      pathnames: [
        routes.PRODUCT.LIST,
        routes.PRODUCT.UPDATE,
        routes.PRODUCT.BULK_UPLOAD,
      ],
      children: [
        {
          key: routes.PRODUCT.LIST,
          label: "Product List",
          pathnames: [routes.PRODUCT.LIST],
        },
        isAdmin && {
          key: routes.PRODUCT.BULK_UPLOAD,
          label: "Bulk Upload",
          pathnames: [routes.PRODUCT.BULK_UPLOAD],
        },
      ],
    },
    {
      key: "BEAT",
      label: "Beat",
      icon: BeatIcon,
      pathnames: [
        routes.BEAT.LIST,
        routes.BEAT.UPDATE,
        routes.BEAT.PLAN,
        routes.BEAT.PLAN_UPDATE,
        routes.BEAT.BULK_UPLOAD,
      ],
      children: [
        {
          key: routes.BEAT.LIST,
          label: "Beat List",
          pathnames: [routes.BEAT.LIST, routes.BEAT.UPDATE],
        },
        {
          key: routes.BEAT.PLAN,
          label: "Beat Plan",
          pathnames: [routes.BEAT.PLAN, routes.BEAT.PLAN_UPDATE],
        },
        isAdmin && {
          key: routes.BEAT.BULK_UPLOAD,
          label: "Beat Bulk Upload",
          pathnames: [routes.BEAT.BULK_UPLOAD],
        },
      ],
    },
    {
      key: "TARGET",
      label: "Target",
      icon: TargetIcon,
      pathnames: [
        routes.TARGET.SELF,
        routes.TARGET.TEAM,
        routes.TARGET.TEMPLATE,
        routes.TARGET.ASSIGN,
        routes.TARGET.UPDATE,
      ],
      children: [
        {
          key: routes.TARGET.SELF,
          label: "My Target",
          pathnames: [routes.TARGET.SELF],
        },
        hasAccess("VIEW_TARGET_TEMPLATE") && {
          key: routes.TARGET.TEMPLATE,
          label: "Target Template",
          pathnames: [
            routes.TARGET.TEMPLATE,
            routes.TARGET.ASSIGN,
            routes.TARGET.UPDATE,
          ],
        },
        (isAdmin || hasHierarchy) && {
          key: routes.TARGET.TEAM,
          label: "Team Targets",
          pathnames: [routes.TARGET.TEAM],
        },
      ],
    },
    hasAccess("VIEW_STAFF") && {
      key: "STAFF",
      label: "Staff",
      icon: StaffIcon,
      pathnames: [
        routes.STAFF.LIST,
        routes.STAFF.DETAILS,
        routes.STAFF.UPDATE,
        routes.STAFF.MAPPING_BULK_UPLOAD,
        routes.STAFF.BULK_UPLOAD,
      ],
      children: [
        {
          key: routes.STAFF.LIST,
          label: "Staff List",
          pathnames: [routes.STAFF.LIST],
        },
        ...(isAdmin
          ? [
              {
                key: routes.STAFF.BULK_UPLOAD,
                label: "Bulk Upload",
                pathnames: [routes.STAFF.BULK_UPLOAD],
              },
              {
                key: routes.STAFF.MAPPING_BULK_UPLOAD,
                label: "Staff-Customer Mapping Bulk Uploading",
                pathnames: [routes.STAFF.MAPPING_BULK_UPLOAD],
              },
            ]
          : []),
      ],
    },
    hasAccess("VIEW_CUSTOMER") && {
      key: "CUSTOMER",
      label: "Customer",
      icon: CustomerIcon,
      pathnames: [
        routes.CUSTOMER.LIST,
        routes.CUSTOMER.UPDATE,
        routes.CUSTOMER.BULK_UPLOAD,
      ],
      children: [
        {
          key: routes.CUSTOMER.LIST,
          label: "Customer List",
          pathnames: [routes.CUSTOMER.LIST, routes.CUSTOMER.UPDATE],
        },
        isAdmin && {
          key: routes.CUSTOMER.BULK_UPLOAD,
          label: "Bulk Upload",
          pathnames: [routes.CUSTOMER.BULK_UPLOAD],
        },
      ],
    },
    hasAccess("VIEW_ORDER") && {
      key: routes.ORDER.LIST,
      label: "Order",
      icon: OrderIcon,
      pathnames: [routes.ORDER.LIST, routes.ORDER.DETAILS, routes.ORDER.UPDATE],
    },
    hasAccess("VIEW_LEAD") && {
      key: routes.LEAD.LIST,
      label: "Lead",
      icon: LeadIcon,
      pathnames: [routes.LEAD.LIST, routes.LEAD.UPDATE, routes.LEAD.DETAILS],
    },
    hasAccess("VIEW_PAYMENT") && {
      key: routes.PAYMENT.LIST,
      label: "Payment",
      icon: PaymentIcon,
      pathnames: [routes.PAYMENT.LIST],
    },
    {
      key: routes.PICTURE_GALLERY.LIST,
      label: "Picture Gallery",
      icon: PictureGalleryIcon,
      pathnames: [routes.PICTURE_GALLERY.LIST],
    },
    isAdmin && {
      key: routes.STOREFRONT,
      label: "Storefront",
      icon: StorefrontIcon,
      pathnames: [routes.STOREFRONT],
    },
    (hasAccess("CREATE_ORDER_DETAILED_REPORT") ||
      hasAccess("CREATE_ORDER_SUMMARY_REPORT") ||
      hasAccess("CREATE_ORDER_DUMP_REPORT") ||
      hasAccess("CREATE_PAYMENT_SUMMARY_REPORT") ||
      hasAccess("CREATE_PRODUCT_SUMMARY_REPORT") ||
      hasAccess("CREATE_EXPENSE_DETAILED_REPORT") ||
      hasAccess("CREATE_EXPENSE_SUMMARY_REPORT") ||
      hasAccess("CREATE_ATTENDANCE_DETAILED_REPORT") ||
      hasAccess("CREATE_ATTENDANCE_SUMMARY_REPORT") ||
      hasAccess("CREATE_ACTIVITY_DETAILED_REPORT") ||
      hasAccess("CREATE_ACTIVITY_SUMMARY_REPORT") ||
      hasAccess("CREATE_CUSTOMER_SUMMARY_REPORT") ||
      hasAccess("CREATE_EMPLOYEE_WISE_PRODUCT_REPORT") ||
      hasAccess("CREATE_CUSTOMER_WISE_PENDING_ORDER_REPORT") ||
      hasAccess("CREATE_LEAD_DETAILED_REPORT") ||
      hasAccess("CREATE_LEAD_SUMMARY_REPORT") ||
      hasAccess("CREATE_PRODUCT_WISE_PENDING_ORDER_REPORT")) && {
      key: routes.REPORTS.LIST,
      label: "Report",
      icon: ReportIcon,
      pathnames: [
        routes.REPORTS.LIST,
        routes.REPORTS.ACTIVITY,
        routes.REPORTS.ATTENDANCE,
        routes.REPORTS.CUSTOMER,
        routes.REPORTS.CUSTOMER_WISE_PENDING_ORDER,
        routes.REPORTS.EMP_WISE_PRODUCT,
        routes.REPORTS.EXPENSE,
        routes.REPORTS.LEAD,
        routes.REPORTS.ORDER,
        routes.REPORTS.PAYMENT,
        routes.REPORTS.PRODUCT,
        routes.REPORTS.PRODUCT_WISE_PENDING_ORDER,
        routes.REPORTS.STAFF,
      ],
    },
    isAdmin &&
      preference.get("whatsapp_white_label")?.is_account_created && {
        key: "https://www.whatsapp.rupyz.com/",
        label: "Rupyz Whatsapp",
        icon: WhatsappIcon,
        pathnames: [],
      },
    isAdmin && {
      key: routes.STAFF_ROLES.LIST,
      label: "Staff Roles",
      icon: StaffRolesIcon,
      pathnames: [routes.STAFF_ROLES.LIST, routes.STAFF_ROLES.UPDATE],
    },
    {
      key: routes.ACTIVITIES.LIST,
      label: "Activities",
      icon: ActivitiesIcon,
      pathnames: [
        routes.ACTIVITIES.LIST,
        routes.ACTIVITIES.PC_LIST,
        routes.ACTIVITIES.TC_LIST,
        routes.ACTIVITIES.STAFF_DETAILS,
      ],
    },
    {
      key: "EXPENSE",
      label: "Expense",
      icon: ExpenseIcon,
      pathnames: [
        routes.EXPENSE.SELF,
        routes.EXPENSE.UPDATE,
        routes.EXPENSE.UPDATE_SUB_LIST,
        routes.EXPENSE.FOR_APPROVAL,
      ],
      children: [
        {
          key: routes.EXPENSE.SELF,
          label: "My Expense",
          pathnames: [
            routes.EXPENSE.SELF,
            routes.EXPENSE.UPDATE,
            routes.EXPENSE.UPDATE_SUB_LIST,
          ],
        },
        hasAccess("REIMBURSEMENT_APPROVAL") && {
          key: routes.EXPENSE.FOR_APPROVAL,
          label: "Approval",
          pathnames: [routes.EXPENSE.FOR_APPROVAL],
        },
      ],
    },
    (hasAccess("VIEW_PRODUCT_CATEGORY") ||
      hasAccess("VIEW_CUSTOMER_TYPE") ||
      hasAccess("VIEW_LEAD_CATEGORY") ||
      hasAccess("VIEW_UNIT")) && {
      key: routes.SETTINGS.LIST,
      label: "Settings",
      icon: SettingsIcon,
      pathnames: [routes.SETTINGS.LIST],
    },
  ];
};
