import { combineReducers } from "redux";
import { authLoginReducer, authVarifyOTPReducer } from "./authReducer";

import {
  deleteOrderReducer,
  dispatchHistoryReducer,
  dispatchOrderReducer,
  lrUpdateOrderReducer,
  orderReducer,
  updateStatusReducer,
} from "./orderReducer";
import {
  paymentActionAddPaymentReducer,
  paymentByIdReducer,
  paymentReducer,
  deletePaymentReducer,
  paymentActionUpdateStatusReducer,
} from "./paymentReducer";
import {
  AddCustomerReducer,
  customerDetailsReducer,
  customerDistributorReducer,
  customerEditReducer,
  customerLevelDistributorSearchReducer,
  deleteCustomerReducer,
  getSearchCategoryMappingAssignReducer,
  getStaffAssignMappingReducer,
  searchStaffAssignReducer,
} from "./customerReducer";

import {
  createReportOrderReducer,
  reportOrderReducer,
  supportReportFieldReducer,
} from "./reportOrderReducer";
import {
  createReportPaymentReducer,
  reportPaymentReducer,
} from "./reportPaymentReducer";
import {
  addProductCategoryReducer,
  deleteProductCategoryReducer,
  editProductCategoryReducer,
  productCategoryDetailsReducer,
  productCategoryReducer,
} from "./productCategoryReducer";
import {
  addNewCustomerCategoryReducer,
  customerCategoryReducer,
  editCustomerCategoryReducer,
} from "./customerCategoryReducer";
import { orderViewReducer } from "./orderViewReducer";
import { dashboardOverviewReducer } from "./dashboardOverviewReducer";
import { panReducer } from "./panReducer";

import {
  updateNewAddressReducer,
  updateOldAddressReducer,
  whatsappRequiredReducer,
} from "./checkoutReducer";
import {
  bulkNewUploadingReducer,
  bulkUploadingReducer,
} from "./bulkUploadingReducer";
import { distributionDetialReducer } from "./distributorDetailsReducer";
import { orderDetaisGetByIdReducer } from "./orderDetailsGetByIdReducer";
import { paymentGetByIdReducer } from "./getPaymentByIdReducer";
import {
  staffAddPreferencesReducer,
  staffPreferencesReducer,
} from "./preferencesReducer";

import {
  permissionReducer,
  rolePermissionReducer,
  staffRoleAddReducer,
  staffRoleDeleteReducer,
  staffRoleEditReducer,
} from "./rolePermissionReducer";

import {
  productUnitAddReducer,
  productUnitDeleteReducer,
  productUnitReducer,
} from "./productunitReducer";
import {
  createReportProductReducer,
  reportProductReducer,
} from "./reportProductReducer";
import {
  editStaffProfileDetailsReducer,
  editUserProfileDetailsReducer,
  profileEditReducer,
  profileReducer,
  staffProfileReducer,
  userProfileDetailsReducer,
} from "./profileReducer";
import { gstReducer } from "./gstReducer";
import {
  createLeadReducer,
  leadReportReducer,
  mobileNumberCheckReducer,
  searchLeadListReducer,
  singleLeadDataActionReducer,
  updateLeadReducer,
} from "./leadManagementReducer";
import {
  AddReibursementItemReducer,
  AddReimbursementReducer,
  reibursementDeleteReducer,
  reibursementEditReducer,
  reibursementItemDeleteReducer,
  reibursementItemDetailsReducer,
  reibursementItemEditReducer,
  reibursementListReducer,
  reimbursementReducer,
  reimbursementTrackerDetailsReducer,
} from "./reimbursementReducer";
import {
  approvalReimbursementReducer,
  approvalReimbursementStatusReducer,
} from "./approvalReimbursementReducer";
import { checkInReducer } from "./feedBackAndActivityReducer";
import {
  getNotificationReducer,
  updateNotificationReducer,
} from "./pushNotificationReducer";
import {
  deleteAttendanceReducer,
  getAttendanceReducer,
  updateAttendanceReducer,
} from "./attendanceReducer";
import {
  createGoalTemplateReducer,
  customAssignGoalsReducer,
  deleteGoalsReducer,
  getGoalByIdReducer,
  getGoalTemplateReducer,
  getSelfTargetDetailsReducer,
  getUserGoalDetailsReducer,
  searchGoalTemplateReducer,
  updateGoalTemplateReducer,
  updateStaffTargetReducer,
  getSatffGoalReducer,
  getTargetDetailsByIdReducer,
  deleteUserTargetReducer,
  getTeamTargetListReducer,
} from "./goalsReducer";

import {
  addCustomerTypeReducer,
  customerTypeReducer,
  deleteCustomerTypeReducer,
  editCustomerTypeReducer,
} from "./customerTypeReducer";

import {
  createReportExpenseReducer,
  reportExpenseReducer,
} from "./reportExpenseReducer";
import {
  createReportAttendanceReducer,
  reportAttendanceReducer,
} from "./reportAttendanceReducer";
import {
  createTemplateReducer,
  deleteTemplateReducer,
  getReportTemplateListReducer,
  getReportTemplateReducer,
} from "./reportTemplateReducer";
import { pincodeAutoFillReducer } from "./pincodeAutoFillReducer";
import {
  deleteFollowUpReminderReducer,
  followUpReminderDetailsReducer,
} from "./reminderReducer";

const RootReducer = combineReducers({
  authLogin: authLoginReducer,
  authVarifyOTP: authVarifyOTPReducer,

  order: orderReducer,
  orderCreate: createReportOrderReducer,
  supportReportField: supportReportFieldReducer,

  payment: paymentReducer,
  paymentRecordCreate: createReportPaymentReducer,
  deletePayment: deletePaymentReducer,

  customerDistributor: customerDistributorReducer,
  getCustomerDetails: customerDetailsReducer,
  addCustomer: AddCustomerReducer,
  editCustomer: customerEditReducer,
  staffAssignMappings: getStaffAssignMappingReducer,
  deleteCustomer: deleteCustomerReducer,
  searchStaffAssign: searchStaffAssignReducer,
  getCustomerLevelSearchList: customerLevelDistributorSearchReducer,
  getCustomerCategorySearchList: getSearchCategoryMappingAssignReducer,

  getProductCategoryDetails: productCategoryDetailsReducer,
  getDeleteProductCategoryDelete: deleteProductCategoryReducer,

  productCategory: productCategoryReducer,
  editProductCategory: editProductCategoryReducer,
  addProductCategory: addProductCategoryReducer,

  customerCategory: customerCategoryReducer,
  editcustomerCategory: editCustomerCategoryReducer,
  addNewCustomerCategory: addNewCustomerCategoryReducer,

  reportOrder: reportOrderReducer,
  orderView: orderViewReducer,
  updateStatus: updateStatusReducer,

  reportPayment: reportPaymentReducer,
  reportProduct: reportProductReducer,
  createReportProduct: createReportProductReducer,

  reportExpense: reportExpenseReducer,
  createReportExpense: createReportExpenseReducer,

  reportAttendance: reportAttendanceReducer,
  createReportAttendance: createReportAttendanceReducer,

  dashboardOverview: dashboardOverviewReducer,

  updateNewAddress: updateNewAddressReducer,
  updateOldAddress: updateOldAddressReducer,
  whatsappRequired: whatsappRequiredReducer,
  dispatchOrder: dispatchOrderReducer,
  dispatchHistory: dispatchHistoryReducer,
  lrUpdateOrder: lrUpdateOrderReducer,
  deleteOrder: deleteOrderReducer,

  bulkUploadingCsv: bulkUploadingReducer,

  panDetails: panReducer,
  disributor_details: distributionDetialReducer,
  order_detail_byid: orderDetaisGetByIdReducer,

  payment_detail_byid: paymentGetByIdReducer,
  paymentById: paymentByIdReducer,
  paymentActionAddPayment: paymentActionAddPaymentReducer,
  paymentActionUpdateStatus: paymentActionUpdateStatusReducer,

  getStaffPrefernce: staffPreferencesReducer,
  addStaffPrefernce: staffAddPreferencesReducer,

  rolesPermission: rolePermissionReducer,
  permissionList: permissionReducer,
  staffRoleAdd: staffRoleAddReducer,
  staffRoleEdit: staffRoleEditReducer,
  staffRoleDelete: staffRoleDeleteReducer,

  panDetails: panReducer,

  productUnit: productUnitReducer,
  productUnitAdd: productUnitAddReducer,
  productUnitDelete: productUnitDeleteReducer,

  profile: profileReducer,
  editProfile: profileEditReducer,
  profileDetails: userProfileDetailsReducer,
  editUserProfileDetails: editUserProfileDetailsReducer,
  staffProfileDetails: staffProfileReducer,
  editStaffProfileDetails: editStaffProfileDetailsReducer,

  gst: gstReducer,

  // lead
  searchLeadList: searchLeadListReducer,
  createLead: createLeadReducer,
  updateLead: updateLeadReducer,
  mobileNumberCheck: mobileNumberCheckReducer,
  singleLeadDataAction: singleLeadDataActionReducer,
  leadReport: leadReportReducer,

  reibursementTracker: reimbursementReducer,
  addReibursement: AddReimbursementReducer,
  editReibursement: reibursementEditReducer,
  getReibursmentList: reibursementListReducer,
  getReibursementTrackerDetails: reimbursementTrackerDetailsReducer,
  addReibursementItem: AddReibursementItemReducer,
  getReimbursementItemDetails: reibursementItemDetailsReducer,
  getReibursementItemEdit: reibursementItemEditReducer,
  deleteReibursement: reibursementDeleteReducer,
  deleteReibursementItem: reibursementItemDeleteReducer,

  approvalReibursementTracker: approvalReimbursementReducer,
  approvalReimbursementStatus: approvalReimbursementStatusReducer,

  checkInAction: checkInReducer,

  // notifications Reducers
  getNotification: getNotificationReducer,
  updateNotification: updateNotificationReducer,

  // attendance Reducers
  getAttendance: getAttendanceReducer,
  updateAttendance: updateAttendanceReducer,
  deleteAttendance: deleteAttendanceReducer,

  // Goal
  getGoalTemplate: getGoalTemplateReducer,
  getGoalById: getGoalByIdReducer,
  createGoalTemplate: createGoalTemplateReducer,
  updateGoalTemplate: updateGoalTemplateReducer,
  deleteGoals: deleteGoalsReducer,
  customAssignGoals: customAssignGoalsReducer,
  getUserGoalDetails: getUserGoalDetailsReducer,
  getSelfTargetDetails: getSelfTargetDetailsReducer,
  updateStaffTarget: updateStaffTargetReducer,
  searchGoalTemplate: searchGoalTemplateReducer,
  getSatffGoalDetails: getSatffGoalReducer,
  getTargetDetailsById: getTargetDetailsByIdReducer,
  deletedTargetDetails: deleteUserTargetReducer,
  teamTargetList: getTeamTargetListReducer,

  //customer Type
  getCustomerType: customerTypeReducer,
  addCustomerType: addCustomerTypeReducer,
  editCustomerType: editCustomerTypeReducer,
  deleteCustomerType: deleteCustomerTypeReducer,

  // bulk upload
  bulkNewUploading: bulkNewUploadingReducer,

  // Reducer for Report Template
  getReportTemplateList: getReportTemplateListReducer,
  getReportTemplate: getReportTemplateReducer,
  createTemplate: createTemplateReducer,
  deleteTemplate: deleteTemplateReducer,

  // City and state Autofill through the pincode
  pincodeAutoFill: pincodeAutoFillReducer,

  // reminder notification
  followUpReminderDetailsReducer,
  deleteFollowUpReminderReducer,
});

export default RootReducer;
