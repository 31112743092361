const SvgDefs = () => {
  const styles = getComputedStyle(document.body);
  const fill = styles.getPropertyValue("--color-theme-1");
  return (
    <div style={{ height: 0 }}>
      <svg>
        <defs>
          <linearGradient id="theme-back" x1="20%" y1="20%" x2="100%" y2="60%">
            <stop offset="0%" stopColor={fill} />
            <stop offset="100%" stopColor={fill + "B3"} />
          </linearGradient>
        </defs>
        <path />
      </svg>
    </div>
  );
};

export default SvgDefs;
