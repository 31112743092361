import { checkout } from "../constant";

const initialState = {
  data: "",
};

export const updateNewAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case checkout.UPDATE_NEW_ADDRESS:
      return { ...state, data: action.payload };

    default:
      return { ...state };
  }
};

export const updateOldAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case checkout.UPDATE_OLD_ADDRESS:
      return { ...state, data: action.payload };

    default:
      return { ...state };
  }
};

export const whatsappRequiredReducer = (state = initialState, action) => {
  switch (action.type) {
    case checkout.WHATSAPP_REQUIRED:
      return { ...state, data: action.payload };

    default:
      return { ...state };
  }
};
