import moment from "moment";
import { Drawer, Tooltip } from "antd";
import styles from "./styles.module.css";
import SalesDetailsTable from "./salesDetailsTable";
import staffIcon from "../../../assets/navbarImages/staff.svg";
import noHistoricData from "../../../assets/globle/no-results.svg";
import noData from "../../../assets/activity-details-header/nodata.svg";

export default function SalesReportDrawer({ data, onClose }) {
  const queryParameters = new URLSearchParams(window.location.search);
  const date = queryParameters.get("date");

  return (
    <Drawer
      open={!!Object.keys(data).length}
      onClose={() => onClose()}
      width={550}
      title={<div style={{ textAlign: "center" }}>Daily Sales Report</div>}
      styles={{
        header: {
          borderBottom: "none",
          paddingBlockStart: "2em",
        },
      }}
    >
      {data?.date ? (
        !!Object.keys(data || {}).length && (
          <div className={`${styles.report_details} ${styles.flex_col_2}`}>
            <div className={styles.space_between}>
              <div className={styles.flex_5}>
                <img
                  src={data?.profile_pic_url || staffIcon}
                  alt={data?.user_name}
                  className={styles.profile_img}
                />
                <Tooltip placement="topLeft" title={data?.user_name}>
                  <p
                    className={`${styles.bold_black} ${styles.text_overflow}`}
                    style={{ fontSize: 16 }}
                  >
                    {data?.user_name}
                  </p>
                </Tooltip>
              </div>
              <p style={{ fontWeight: 600, color: "#464646" }}>
                {moment(data?.date).format("DD MMM YYYY")}
              </p>
            </div>
            <SalesDetailsTable
              reportDetails={{
                ...data,
                params: {
                  date: date
                    ? moment(date).format("DD-MM-YYYY")
                    : moment().format("DD-MM-YYYY"),
                },
              }}
            />
          </div>
        )
      ) : (
        <div className={styles.no_data}>
          <img src={!data?.date ? noData : noHistoricData} alt="no-data" />
          <div>
            {!data?.date ? (
              "No Activities Performed"
            ) : (
              <>
                <p className={styles.bold_black}>No Data Available</p>
                Data in this "Daily sales Report" is not available for this date
              </>
            )}
          </div>
        </div>
      )}
    </Drawer>
  );
}
