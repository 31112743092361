import { Modal } from "antd";
import React, { useContext } from "react";
import styles from "./confirmDelete.module.css";
import Context from "../../context/Context";

const ConfirmDelete = ({
  title,
  deleteModal,
  setDeleteModal,
  confirmValue,
  noteMessage,
}) => {
  const context = useContext(Context);
  const { deleteModalOpen, setDeleteModalOpen } = context;

  const onCancel = () => {
    !deleteModal ? setDeleteModalOpen(false) : setDeleteModal(false);
  };

  const onSubmit = () => {
    confirmValue(true);
    setTimeout(() => onCancel(), 500);
  };

  return (
    title && (
      <Modal
        centered
        open={deleteModalOpen || deleteModal}
        className={styles.delet_main}
        onCancel={onCancel}
        data-testid="confirmDelete-modal"
        title={
          <div>
            <div
              style={{
                padding: 15,
                textAlign: "center",
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              {title === "Payment Gateway"
                ? "Deactivate Payment Gateway "
                : `Delete ${title} `}
              ?
            </div>
            <div className={styles.delet_p} style={{ fontSize: 15 }}>
              Are You Sure, You want to{" "}
              {title === "Payment Gateway" ? "Deactivate" : "Delete"} Your{" "}
              {title} ?
            </div>
            <div
              className={styles.delet_p}
              style={{ fontSize: 12, marginTop: 10 }}
            >
              {noteMessage}
            </div>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                background: "#fff",
                padding: 15,
                flexDirection: "row-reverse",
                borderRadius: "0 0 10px 10px",
              }}
              className={styles.edit_header}
            >
              <button
                className="button_primary"
                onClick={onSubmit}
                data-testid={`confirmDelete-${(title === "Payment Gateway"
                  ? "Deactivate"
                  : "Delete"
                ).toLowerCase()}_button`}
              >
                {title === "Payment Gateway" ? "Deactivate" : "Delete"}
              </button>
              <button
                className="button_secondary"
                style={{ marginRight: 20 }}
                onClick={onCancel}
                data-testid="confirmDelete-cancel_button"
              >
                Cancel
              </button>
            </div>
          </div>
        }
        footer={[]}
        style={{ fontFamily: "Poppins" }}
        styles={{
          header: {
            borderRadius: 8,
          },
        }}
      />
    )
  );
};

export default ConfirmDelete;
