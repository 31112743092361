export function roundToDecimalPlaces(value, decimalPlaces = 2) {
  if (!value) return 0;
  const dp = Math.pow(10, decimalPlaces);

  const num = parseFloat(value);
  return Math.round(num * dp) / dp;
}

export function calculatePercentage(price, percent) {
  const percentageAmount = price * (percent / 100);
  return percentageAmount;
}
