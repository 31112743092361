import React, { useState, useEffect } from "react";
import { Button, Col, Input, Radio, Space } from "antd";
import { createValidationProps } from "../../helpers/validations";
import apiService from "../../../services/api-service";
import errorMsgs from "../../constants/messages.json";

const accessTypes = [
  { label: "Staff", value: "WEB_STAFF" },
  { label: "Admin", value: "WEB_SARE360" },
];

const LoginForm = ({ onSuccess }) => {
  const [username, setUsername] = useState("");
  const [error, setError] = useState({ username: false, otp: false });
  const [accessType, setAccessType] = useState(accessTypes[0]["value"]);
  const [otpRef, setOtpRef] = useState("");
  const [otp, setOtp] = useState("");
  const [otpTimer, setOtpTimer] = useState(0);

  async function loginUser() {
    if (!username || username.length < 10)
      return setError({ ...error, username: true });

    const response = await apiService.loginUser({
      username,
      access_type: accessType,
    });
    if (response) {
      const { otp_ref } = response;
      setOtpRef(otp_ref || "");
      setOtpTimer(30);
    }
  }

  async function verifyUser() {
    if (!otp) {
      return setError({ ...error, otp: true });
    }
    const response = await apiService.verifyUser({
      otp,
      otp_ref: otpRef,
      username,
      access_type: accessType,
      terms_condition: true,
      is_smart_match: true,
      preferences: {
        WHATSAPP_OTP_IN: true,
      },
    });
    if (response) {
      onSuccess(response);
    }
  }

  useEffect(() => {
    let interval;
    if (otpTimer > 0) {
      interval = setInterval(() => {
        setOtpTimer((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [otpTimer]);

  if (username && otpRef)
    return (
      <Space direction="vertical" size={20} style={{ width: "100%" }}>
        <Col align="left">
          <h4 data-testid="loginForm-username" data-value={username}>
            OTP sent to <b>{username}</b>
          </h4>
        </Col>
        <div style={{ position: "relative" }}>
          <Input
            {...(error.otp && { status: "error" })}
            data-testid="loginForm-otp_input"
            placeholder="OTP"
            size="large"
            maxLength={4}
            {...createValidationProps({ name: "num" })}
            onChange={(e) => {
              if (error.otp) setError({ ...error, otp: false });
              setOtp(e.target.value || "");
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                verifyUser();
              }
            }}
          />
          {error.otp && (
            <span
              style={{
                position: "absolute",
                top: "100%",
                left: 10,
                color: "var(--color-error)",
              }}
            >
              {errorMsgs.LOGIN.OTP_ERROR}
            </span>
          )}
        </div>
        <div />

        <h4 style={!otpTimer ? { textDecoration: "underline" } : {}}>
          <span
            className={!otpTimer ? "clickable" : ""}
            data-testid="loginForm-resend_otp"
            onClick={() => {
              if (!otpTimer) loginUser();
            }}
          >
            Resend OTP{" "}
          </span>
          {!!otpTimer && (
            <span>
              in <b>{otpTimer} seconds</b>
            </span>
          )}
        </h4>

        <Button
          type="primary"
          size="large"
          block
          onClick={verifyUser}
          data-testid="loginForm-otp_submit_button"
        >
          Submit
        </Button>
        <Button
          data-testid="loginForm-otp_back_button"
          size="large"
          block
          onClick={() => {
            setOtpRef("");
            setUsername("");
          }}
        >
          Back
        </Button>
      </Space>
    );

  return (
    <Space direction="vertical" size={25} style={{ width: "100%" }}>
      <div style={{ position: "relative" }}>
        <Input
          {...(error.username && { status: "error" })}
          name="phone"
          placeholder="Phone Number"
          data-testid="loginForm-phone_number_input"
          size="large"
          maxLength={10}
          {...createValidationProps({ name: "num" })}
          onChange={(e) => {
            if (error.username) setError({ ...error, username: false });
            setUsername(e.target.value || "");
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              loginUser();
            }
          }}
        />
        {error.username && (
          <span
            style={{
              position: "absolute",
              top: "100%",
              left: 10,
              color: "var(--color-error)",
            }}
          >
            {errorMsgs.GENERAL.MOBILE_ERROR}
          </span>
        )}
      </div>

      <Radio.Group
        defaultValue={accessType}
        onChange={(e) => {
          setAccessType(e.target.value);
        }}
        block
        optionType="button"
        buttonStyle="solid"
        style={{ width: 200 }}
      >
        {accessTypes.map((type) => (
          <Radio.Button
            data-testid="loginForm-accesstype"
            key={type.value}
            value={type.value}
            data-value={type.label}
          >
            {type.label}
          </Radio.Button>
        ))}
      </Radio.Group>

      <Button
        type="primary"
        size="large"
        block
        onClick={loginUser}
        data-testid="loginForm-submit_button"
      >
        Submit
      </Button>
    </Space>
  );
};

export default LoginForm;
