export default function ActivitiesIcon({ fill = "#727176", size = "24" }) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 11.25C13.75 13.325 12.075 15 10 15C7.925 15 6.25 13.325 6.25 11.25C6.25 9.175 7.925 7.5 10 7.5C12.075 7.5 13.75 9.175 13.75 11.25ZM17.5 25H2.5V22.5C2.5 19.7375 5.8625 17.5 10 17.5C14.1375 17.5 17.5 19.7375 17.5 22.5M27.5 15V17.5H16.25V15M27.5 10V12.5H16.25V10M27.5 5V7.5H16.25V5H27.5Z"
        {...{ fill }}
      />
    </svg>
  );
}
