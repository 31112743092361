import axios from "axios";
import { BASE_URL_V2, org_id } from "../../config";
import SessionExpireError from "../../helpers/sessionExpireError";
import { reminder } from "../constant";
import { headers } from "../../services/api-service";

export const followUpReminderDetails = (id) => (dispatch) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/followup/${id}/`;

  axios
    .get(url, { headers })
    .then((response) => {
      dispatch({
        type: reminder.LIST_FOLLOW_UP_REMINDER,
        payload: response,
      });
      setTimeout(() => {
        dispatch({
          type: reminder.LIST_FOLLOW_UP_REMINDER,
          payload: "",
        });
      }, 100);
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const deleteFollowUpReminder = (id, formData) => (dispatch) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/followup/${id}/`;

  // need to change approach
  const updatedCustomFormData = formData.custom_form_data?.map((item) => ({
    ...item,
    value: "",
  }));
  const data = {
    ...formData,
    due_datetime: "",
    custom_form_data: updatedCustomFormData,
  };
  axios
    .post(url, data, { headers })
    .then((response) => {
      dispatch({
        type: reminder.DELETE_FOLLOW_UP_REMINDER,
        payload: response,
      });
      setTimeout(() => {
        dispatch({
          type: reminder.DELETE_FOLLOW_UP_REMINDER,
          payload: "",
        });
      }, 100);
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};
