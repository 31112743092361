import axios from "axios";
import { notification } from "antd";
import { BASE_URL_V2, org_id } from "../../config.js";
import SessionExpireError from "../../helpers/sessionExpireError.js";
import { headers } from "../../services/api-service.js";

const baseUrl = `${BASE_URL_V2}/organization/${org_id}/staff/`;
const baseUrlwithES = `${BASE_URL_V2}/organization/${org_id}/staff/es/`;

export const getStaffList = async (params = {}) => {
  return axios
    .get(baseUrlwithES, { headers, params })
    .then((res) => {
      return res.data.data || [];
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const updateStaff = async (formData) => {
  const url = formData?.id ? baseUrl + formData.id + "/" : baseUrl;

  return axios
    .post(url, formData, { headers })
    .then((res) => {
      if (res.status === 200) {
        notification.success({
          message: res.data.message,
        });
      }
      return res;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: err.response.data.message,
      });
    });
};

export const getStaffDetails = (id) => {
  if (id === undefined) return;
  const url = baseUrl + `${id}/`;
  return axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const deleteStaff = async (id) => {
  const url = baseUrl + `${id}/delete/`;

  return axios
    .post(url, {}, { headers })
    .then((res) => {
      if (res.status === 200) {
        notification.success({
          message: res.data.message,
        });
      }
      return res;
    })
    .catch((error) => {
      SessionExpireError(error.response);
      notification.success({
        message: error.response.data.message,
      });
    });
};

export const fetchStaffActivities = async (params = {}, user_id) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/activitylog/user/${user_id}/`;

  return axios
    .get(url, { headers, params: { with_location_points: true, ...params } })
    .then((res) => res)
    .catch((error) => {
      SessionExpireError(error.response);
    });
};
