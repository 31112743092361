import axios from "axios";
import { notification } from "antd";
import Cookies from "universal-cookie";
import { preference } from "./preference-service";
import hasAccess from "./permissions";
import message from "../refactor/constants/messages.json";
import authService from "./auth-service";

const cookies = new Cookies();

const userDetails = authService.fetchUserDetails();

export const BASE_URL_V1 = process.env.REACT_APP_BASE_URL_V1;
export const BASE_URL_V2 = process.env.REACT_APP_BASE_URL_V2;

export const orgId = userDetails?.org_id;
export const headers = {
  Authorization: `Bearer ${userDetails?.credentials?.access_token}`,
  source: "WEB",
};

export const successHandler = (response = {}) => {
  const data = response.data || {};
  if (!data.error && data.message) {
    notification.success({
      message: data.message,
    });
  }
  return data.data;
};

export const errorHandler = (
  error = {},
  callback = ({ message }) => {
    notification.warning({
      message: <div dangerouslySetInnerHTML={{ __html: message }} />,
    });
  }
) => {
  const response = (error || {})["response"];
  // unauthorized error handling
  if (response) {
    switch (response.status) {
      case 403:
        notification.warning({
          message: `Session Expired`,
          duration: 1,
        });
        cookies.set("rupyzToken", "", { path: "/" });
        window.location.reload();
        return {};
    }
    callback(
      response.data || {
        message: message.GENERAL.API_ERROR,
      }
    );
  }
};

const fetchAppStatus = async () => {
  const url = `${BASE_URL_V1}/masterapp/maintenance/`;
  return axios.get(url).then((res) => res);
};
const initFCM = async (data) => {
  const url = `${BASE_URL_V1}/notification/fcm-device/`;
  return axios.post(url, data, { headers }).then((res) => res);
};

// User Details
const syncUserDetails = async () => {
  const url = hasAccess()
    ? `${BASE_URL_V1}/user/profile/`
    : `${BASE_URL_V2}/organization/staff/profile/`;

  return axios
    .get(url, { headers, params: orgId })
    .then((res) => {
      cookies.set(
        "checkIn",
        res.data.data.checkin_time === ""
          ? ""
          : res.data.data.checkin_time !== "" &&
            res.data.data.checkout_time !== ""
          ? "Check Out"
          : res.data.data.checkin_time !== "" &&
            res.data.data.checkout_time === "" &&
            "Check In",
        { path: "/" }
      );
      cookies.set("rupyzPermissionType", res.data.data.permissions, {
        path: "/",
      });
      return res;
    })
    .catch(errorHandler);
};

// Preferences
const syncPreferences = async () => {
  const url = `${BASE_URL_V2}/organization/${orgId}/preferences/`;
  return axios
    .get(url, { headers })
    .then((res) => {
      preference.set(res.data.data);
      cookies.set(
        "rupyzCustomerLevelConfig",
        res.data.data.customer_level_config,
        { path: "/" }
      );
      cookies.set("rupyzStaffEnable", res.data.data.staff_customer_mapping, {
        path: "/",
      });
      cookies.set(
        "rupyzRolePermissionEnable",
        res.data.data.enable_roles_permission,
        { path: "/" }
      );
      cookies.set("rupyzLocationEnable", res.data.data.location_tracking, {
        path: "/",
      });
      cookies.set(
        "rupyzHierarchyEnable",
        res.data.data.enable_hierarchy_management,
        {
          path: "/",
        }
      );
      cookies.set(
        "rupyzAnalyticsEnable",
        res.data.data.enable_analytics_calculation,
        {
          path: "/",
        }
      );
      cookies.set("rupyzGalleryEnable", !res.data.data.disable_gallery_photo, {
        path: "/",
      });
      cookies.set(
        "rupyzCustomrCategoryMappingEnabled",
        res.data.data.enable_customer_category_mapping,
        {
          path: "/",
        }
      );
      cookies.set(
        "rupyzCustomerLevelOrderEnable",
        res.data.data.enable_customer_level_order,

        {
          path: "/",
        }
      );
      cookies.set(
        "rupyzEnableGeoFencing",
        res.data.data.activity_geo_fencing,

        {
          path: "/",
        }
      );
      cookies.set(
        "rupyzEnableLiveLocationTrace",
        res.data.data.live_location_tracking,

        {
          path: "/",
        }
      );
      return res.data.data;
    })
    .catch(errorHandler);
};
const updatePreferences = async (data) => {
  const url = `${BASE_URL_V2}/organization/${orgId}/preferences/`;
  return axios
    .post(url, data, { headers })
    .then((res) => {
      notification.success({ message: res.data.message });
      preference.set(res.data.data);
      return res;
    })
    .catch(errorHandler);
};

// Login
const loginUser = async (formdata) => {
  const url = `${BASE_URL_V1}/user/initiate_login/`;
  return axios.post(url, formdata).then(successHandler).catch(errorHandler);
};
const verifyUser = async (formdata) => {
  const url = `${BASE_URL_V1}/user/logged_in/`;
  return axios.post(url, formdata).then(successHandler).catch(errorHandler);
};

// contact us
const contactUs = async (formData) => {
  const url = `${BASE_URL_V1}/masterapp/contactus/public/`;
  return axios
    .post(url, formData, { headers })
    .then(successHandler)
    .catch(errorHandler);
};

// Dashboard
const fetchDashboardCounters = async () => {
  const url = `${BASE_URL_V2}/organization/${orgId}/sales/dashboard/`;
  return axios
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch(errorHandler);
};
const fetchAnalyticsDashboardParams = async () => {
  const url = `${BASE_URL_V2}/organization/${orgId}/analytics/power-bi/`;
  const response = await axios.get(url, { headers });
  return response;
};

// Beat Plan
const fetchBeatPlanDetails = async (params = {}, status) => {
  const url =
    status === "ACTIVE"
      ? `${BASE_URL_V2}/organization/${orgId}/beatroute/${params.user_id}/active/`
      : `${BASE_URL_V2}/organization/${orgId}/beatroute/${params.id}/`;

  return axios
    .get(url, { headers, params })
    .then((res) => res)
    .catch(errorHandler);
};

export default {
  fetchAppStatus,
  initFCM,

  // Preferences
  syncPreferences,
  updatePreferences,

  // Login
  loginUser,
  verifyUser,

  //Contact Us
  contactUs,

  // Dashboard
  fetchDashboardCounters,
  fetchAnalyticsDashboardParams,

  // User details
  syncUserDetails,

  // Beat Plan
  fetchBeatPlanDetails,
};
