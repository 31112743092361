export default function WhatsappIcon({ fill = "#727176", size = "24" }) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5_2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 2.5C8.09625 2.5 2.5 8.09625 2.5 15C2.5 17.3625 3.15625 19.575 4.2975 21.46L3.1825 25.25C3.11848 25.4676 3.11427 25.6984 3.17031 25.9182C3.22634 26.138 3.34056 26.3387 3.50095 26.4991C3.66134 26.6594 3.86198 26.7737 4.08177 26.8297C4.30156 26.8857 4.5324 26.8815 4.75 26.8175L8.54 25.7025C10.4883 26.8814 12.7228 27.5031 15 27.5C21.9037 27.5 27.5 21.9037 27.5 15C27.5 8.09625 21.9037 2.5 15 2.5ZM12.1725 17.8287C14.7012 20.3562 17.115 20.69 17.9675 20.7212C19.2637 20.7687 20.5262 19.7788 21.0175 18.63C21.0795 18.4871 21.102 18.3302 21.0827 18.1756C21.0633 18.021 21.0028 17.8745 20.9075 17.7513C20.2225 16.8763 19.2963 16.2475 18.3913 15.6225C18.2023 15.4919 17.9701 15.4394 17.7433 15.4763C17.5166 15.5131 17.3129 15.6363 17.175 15.82L16.425 16.9638C16.3856 17.0252 16.3241 17.0693 16.2532 17.087C16.1824 17.1047 16.1074 17.0945 16.0438 17.0588C15.535 16.7675 14.7938 16.2725 14.2613 15.74C13.7288 15.2075 13.2638 14.5 13.0025 14.0237C12.9701 13.9632 12.9607 13.893 12.9761 13.8262C12.9915 13.7593 13.0307 13.7003 13.0862 13.66L14.2412 12.8025C14.4061 12.6592 14.5125 12.4602 14.5401 12.2436C14.5678 12.0269 14.5148 11.8076 14.3912 11.6275C13.8312 10.8075 13.1788 9.765 12.2325 9.07375C12.1104 8.98525 11.9673 8.93007 11.8174 8.91364C11.6675 8.8972 11.5159 8.92007 11.3775 8.98C10.2275 9.4725 9.2325 10.735 9.28 12.0337C9.31125 12.8862 9.645 15.3 12.1725 17.8287Z"
          {...{ fill }}
        />
      </g>
      <defs>
        <clipPath id="clip0_5_2">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
