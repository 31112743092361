const fetchUserDetails = () => {
  return JSON.parse(localStorage.getItem("rupyz_user-v1")) || {};
};

const setUserDetails = (data) => {
  return localStorage.setItem("rupyz_user-v1", JSON.stringify(data));
};

export default {
  fetchUserDetails,
  setUserDetails,
};
