export default function TargetIcon({ fill = "#727176", size = "24" }) {
  return (
    <svg
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <path
        d="M23.805 3.40168H23.8031L26.8409 4.00887C27.004 4.0415 27.1381 4.16112 27.1853 4.32243C27.2091 4.40079 27.2112 4.48414 27.1914 4.56361C27.1716 4.64308 27.1307 4.7157 27.0729 4.77375L24.5843 7.26412C24.2866 7.56131 23.8831 7.72819 23.4624 7.72812H21.3998L16.216 12.9137C16.3149 13.2871 16.3331 13.6773 16.2694 14.0583C16.2056 14.4393 16.0614 14.8023 15.8464 15.1232C15.6314 15.4441 15.3504 15.7155 15.0222 15.9193C14.6941 16.1231 14.3263 16.2546 13.9433 16.3051C13.5603 16.3556 13.171 16.324 12.8012 16.2122C12.4315 16.1004 12.0898 15.9111 11.7989 15.657C11.508 15.4028 11.2746 15.0895 11.1143 14.7381C10.954 14.3866 10.8704 14.005 10.8691 13.6187C10.8694 13.2017 10.9656 12.7902 11.1502 12.4163C11.3348 12.0423 11.6029 11.7157 11.9339 11.4619C12.2648 11.2081 12.6497 11.0337 13.0587 10.9523C13.4678 10.8709 13.8901 10.8846 14.293 10.9924L19.4785 5.80506V3.74425C19.4785 3.32375 19.6453 2.91956 19.9425 2.62231L22.4329 0.131934C22.4909 0.0741671 22.5636 0.0332128 22.643 0.0134255C22.7225 -0.0063618 22.8058 -0.00424234 22.8842 0.0195585C23.0455 0.0666835 23.1651 0.200808 23.1978 0.363933L23.805 3.40168Z"
        {...{ fill }}
      />
      <path
        d="M2.7129 13.6187C2.71476 15.1738 3.0501 16.7103 3.6963 18.1248C4.3425 19.5392 5.28453 20.7986 6.45884 21.818C7.63315 22.8374 9.01243 23.5931 10.5036 24.0342C11.9948 24.4752 13.5633 24.5913 15.1032 24.3746C16.6431 24.1579 18.1186 23.6135 19.4302 22.7781C20.7418 21.9427 21.8589 20.8357 22.7063 19.5319C23.5538 18.228 24.1117 16.7575 24.3425 15.2197C24.5732 13.6818 24.4715 12.1124 24.0442 10.6172C23.9842 10.4425 23.9604 10.2575 23.9742 10.0733C23.988 9.8891 24.0392 9.70966 24.1245 9.54587C24.2099 9.38209 24.3277 9.23739 24.4708 9.12059C24.6139 9.00379 24.7792 8.91733 24.9568 8.86648C25.1344 8.81563 25.3204 8.80144 25.5036 8.82478C25.6868 8.84813 25.8634 8.90852 26.0225 9.00227C26.1817 9.09603 26.3201 9.22119 26.4293 9.37013C26.5385 9.51908 26.6163 9.6887 26.6578 9.86866C27.4853 12.7642 27.3281 15.8525 26.211 18.649C25.0938 21.4456 23.0799 23.7921 20.4852 25.3206C17.8905 26.849 14.8618 27.4729 11.8743 27.0943C8.88674 26.7157 6.10941 25.356 3.97802 23.2286C1.84896 21.0977 0.487828 18.3201 0.108342 15.3319C-0.271144 12.3437 0.352502 9.31406 1.88136 6.71866C3.41023 4.12327 5.75773 2.10909 8.5553 0.992372C11.3529 -0.124349 14.4421 -0.280379 17.338 0.54878C17.6828 0.649576 17.9737 0.882765 18.1471 1.19739C18.3205 1.51201 18.3623 1.88248 18.2634 2.22784C18.1645 2.57319 17.9329 2.86536 17.6192 3.04048C17.3055 3.2156 16.9353 3.25943 16.5894 3.1624C14.9701 2.6978 13.265 2.61569 11.6085 2.92255C9.95203 3.22941 8.38947 3.91685 7.04403 4.93068C5.69859 5.9445 4.60704 7.257 3.85543 8.7647C3.10381 10.2724 2.71269 11.9341 2.7129 13.6187Z"
        {...{ fill }}
      />
      <path
        d="M8.1504 13.6188C8.15051 14.6095 8.4211 15.5814 8.93298 16.4296C9.44485 17.2778 10.1786 17.9701 11.0551 18.432C11.9315 18.8938 12.9174 19.1075 13.9065 19.0502C14.8955 18.9929 15.8501 18.6666 16.6673 18.1065C17.4844 17.5451 18.1324 16.771 18.5413 15.8679C18.9502 14.9647 19.1045 13.967 18.9873 12.9826C18.9557 12.7437 18.9882 12.5007 19.0817 12.2786C19.1751 12.0565 19.3261 11.8633 19.519 11.7189C19.712 11.5745 19.9399 11.4842 20.1794 11.4572C20.4188 11.4302 20.6612 11.4675 20.8814 11.5652C21.1016 11.6617 21.2923 11.8149 21.434 12.0091C21.5757 12.2034 21.6633 12.4317 21.688 12.6709C21.8849 14.353 21.5533 16.0548 20.7391 17.54C19.925 19.0251 18.6687 20.22 17.1447 20.9587C15.6206 21.6975 13.9043 21.9435 12.2341 21.6625C10.5639 21.3816 9.0226 20.5877 7.82409 19.391C6.62557 18.1943 5.82932 16.6542 5.54585 14.9844C5.26237 13.3146 5.50574 11.598 6.24218 10.0728C6.97861 8.54763 8.17157 7.28953 9.65549 6.47313C11.1394 5.65673 12.8407 5.32253 14.5232 5.51692C14.7033 5.53363 14.8783 5.58614 15.0379 5.67137C15.1975 5.7566 15.3385 5.87283 15.4526 6.01325C15.5667 6.15366 15.6516 6.31544 15.7023 6.48911C15.7531 6.66277 15.7687 6.84481 15.7482 7.02458C15.7277 7.20434 15.6715 7.3782 15.5829 7.53596C15.4943 7.69373 15.3752 7.83222 15.2324 7.94333C15.0896 8.05444 14.926 8.13593 14.7513 8.18301C14.5767 8.2301 14.3943 8.24184 14.215 8.21754C13.4532 8.12908 12.6812 8.2028 11.9498 8.43386C11.2185 8.66492 10.5443 9.04809 9.97151 9.5582C9.39874 10.0683 8.94038 10.6938 8.62652 11.3937C8.31265 12.0935 8.1504 12.8518 8.1504 13.6188Z"
        {...{ fill }}
      />
    </svg>
  );
}
