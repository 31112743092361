import { Col, Row } from "antd";

const MaintenancePage = () => {
  return (
    <Row justify={"center"} align={"middle"} className="app-layout refactor">
      <Col align="middle">
        <img
          src={require("../../assets/placeholders/maintenance.svg").default}
          alt="maintenance-placeholder"
        />
        <h2>Site is Under Maintenance</h2>
        <br />
        <p className="subtitle">Please try again after sometime</p>
        <br />
      </Col>
    </Row>
  );
};

export default MaintenancePage;
