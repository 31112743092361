import axios from "axios";
import { BASE_URL_V2, org_id } from "../../config.js";
import SessionExpireError from "../../helpers/sessionExpireError.js";
import { customer } from "../constant.js";
import { headers } from "../../services/api-service.js";

export const customerViewDetails = (id) => (dispatch) => {
  if (id === undefined) return;
  const url = `${BASE_URL_V2}/organization/${org_id}/customer/${id}/`;
  axios
    .get(url, { headers })
    .then((response) => {
      dispatch({
        type: customer.CUSTOMER_DISTRIBUTOR_DETAILS,
        payload: response,
      });
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const customerInsightsAction = async (customer_id, params = {}) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/customer/${customer_id}/insights/`;
  return axios
    .get(url, { headers, params })
    .then((response) => {
      return response["data"];
    })
    .catch((error) => {
      // SessionExpireError(error.response);
    });
};
