export default function StaffRolesIcon({ fill = "#727176", size = "24" }) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 26.25H16.25C15.587 26.25 14.9511 25.9866 14.4822 25.5178C14.0134 25.0489 13.75 24.413 13.75 23.75C13.75 23.087 14.0134 22.4511 14.4822 21.9822C14.9511 21.5134 15.587 21.25 16.25 21.25H18.75V18.75H16.25C14.9239 18.75 13.6521 19.2768 12.7145 20.2145C11.7768 21.1521 11.25 22.4239 11.25 23.75C11.25 25.0761 11.7768 26.3479 12.7145 27.2855C13.6521 28.2232 14.9239 28.75 16.25 28.75H18.75V26.25ZM28.75 23.75C28.75 25.0761 28.2232 26.3479 27.2855 27.2855C26.3479 28.2232 25.0761 28.75 23.75 28.75H21.25V26.25H23.75C24.413 26.25 25.0489 25.9866 25.5178 25.5178C25.9866 25.0489 26.25 24.413 26.25 23.75C26.25 23.087 25.9866 22.4511 25.5178 21.9822C25.0489 21.5134 24.413 21.25 23.75 21.25H21.25V18.75H23.75C25.0761 18.75 26.3479 19.2768 27.2855 20.2145C28.2232 21.1521 28.75 22.4239 28.75 23.75Z"
        {...{ fill }}
      />
      <path
        d="M17.5 22.5003H22.5V25.0003H17.5V22.5003ZM8.75 23.7503C8.75245 23.3312 8.79008 22.913 8.8625 22.5003H3.75V20.7503C3.75 18.2503 8.75 16.8753 11.25 16.8753C11.8158 16.8826 12.38 16.9348 12.9375 17.0315C13.9665 16.5187 15.1003 16.2513 16.25 16.2503H22.5V5.00025C22.498 4.33782 22.234 3.70308 21.7656 3.23467C21.2972 2.76626 20.6624 2.50223 20 2.50025H14.775C14.519 1.76955 14.0423 1.13645 13.4108 0.688526C12.7793 0.240603 12.0242 0 11.25 0C10.4758 0 9.72067 0.240603 9.08916 0.688526C8.45765 1.13645 7.98095 1.76955 7.725 2.50025H2.5C1.83757 2.50223 1.20283 2.76626 0.734419 3.23467C0.266006 3.70308 0.00197841 4.33782 0 5.00025L0 22.5003C0.00197841 23.1627 0.266006 23.7974 0.734419 24.2658C1.20283 24.7342 1.83757 24.9983 2.5 25.0003H8.8625C8.79008 24.5875 8.75245 24.1693 8.75 23.7503ZM11.25 2.50025C11.4972 2.50025 11.7389 2.57356 11.9445 2.71092C12.15 2.84827 12.3102 3.04349 12.4049 3.2719C12.4995 3.50031 12.5242 3.75164 12.476 3.99412C12.4278 4.23659 12.3087 4.45932 12.1339 4.63414C11.9591 4.80895 11.7363 4.928 11.4939 4.97623C11.2514 5.02447 11.0001 4.99971 10.7716 4.9051C10.5432 4.81049 10.348 4.65028 10.2107 4.44472C10.0733 4.23915 10 3.99748 10 3.75025C10.001 3.41904 10.133 3.10167 10.3672 2.86746C10.6014 2.63326 10.9188 2.50124 11.25 2.50025ZM11.25 7.50025C11.9917 7.50025 12.7167 7.72019 13.3334 8.13224C13.9501 8.5443 14.4307 9.12997 14.7145 9.81519C14.9984 10.5004 15.0726 11.2544 14.9279 11.9818C14.7833 12.7093 14.4261 13.3775 13.9017 13.9019C13.3772 14.4264 12.709 14.7835 11.9816 14.9282C11.2542 15.0729 10.5002 14.9986 9.81494 14.7148C9.12971 14.431 8.54404 13.9503 8.13199 13.3336C7.71993 12.717 7.5 11.9919 7.5 11.2503C7.49934 10.7576 7.59589 10.2697 7.78411 9.81441C7.97233 9.35914 8.24853 8.94549 8.59688 8.59713C8.94523 8.24878 9.35889 7.97259 9.81416 7.78436C10.2694 7.59614 10.7574 7.4996 11.25 7.50025Z"
        {...{ fill }}
      />
    </svg>
  );
}
