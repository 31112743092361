import {
  Purpose,
  NightStay,
  NoPlanIcon,
  NewVisitAreaIcon,
  NoPlanOutlinedIcon,
  NewVisitAreaOutlinedIcon,
} from "../../../assets/beat";
import moment from "moment";
import { useEffect, useState } from "react";
import styles from "../beatPlanList.module.css";
import { Dropdown, Input, notification } from "antd";
import { BeatPlan } from "../../../assets/navbarImages";
import { CloseOutlined, MoreOutlined } from "@ant-design/icons";
import MultiSelectSearch from "../../../components/selectSearch/multiSelectSearch";

export default function FieldAction({
  type,
  errors,
  onChange,
  fieldValues,
  isStaffSelected,
  selectBeatParams,
}) {
  const isDatePassed = moment(fieldValues?.date).isBefore(
    moment().startOf("day")
  );

  const [activeModule, setActiveModule] = useState({
    main_module: fieldValues?.module_type || "BEAT",
    sub_module:
      fieldValues?.night_stay && fieldValues?.purpose
        ? ["purpose", "night_stay"]
        : [
            ...(fieldValues?.night_stay ? ["night_stay"] : []),
            ...(fieldValues?.purpose ? ["purpose"] : []),
          ],
  });

  const beatMenuItem = [
    {
      key: "BEAT",
      label: (
        <div
          className="action-dropdown-list"
          onClick={() => {
            setActiveModule({
              main_module: "BEAT",
              sub_module:
                activeModule.main_module !== "HOLIDAY"
                  ? activeModule.sub_module
                  : [],
            });
          }}
        >
          <img src={BeatPlan} alt="beat" />
          Beat
        </div>
      ),
    },
  ];

  const items = [
    ...(activeModule.main_module === "LOCATION"
      ? beatMenuItem
      : [
          {
            key: "LOCATION",
            label: (
              <div
                data-testid="fieldAction-dropdownItem_area_visit"
                className="action-dropdown-list"
                onClick={() => {
                  setActiveModule({
                    main_module: "LOCATION",
                    sub_module:
                      activeModule.main_module !== "HOLIDAY"
                        ? activeModule.sub_module
                        : [],
                  });
                }}
              >
                <img src={NewVisitAreaIcon} alt="area-visit" />
                Visit New Area
              </div>
            ),
          },
        ]),
    ...(activeModule.main_module === "HOLIDAY"
      ? beatMenuItem
      : [
          {
            key: "HOLIDAY",
            label: (
              <div
                data-testid="fieldAction-dropdownItem_no_plan"
                className="action-dropdown-list"
                onClick={() => {
                  setActiveModule({
                    main_module: "HOLIDAY",
                    sub_module: [],
                  });
                }}
              >
                <img src={NoPlanIcon} alt="HOLIDAY" />
                No Plan
              </div>
            ),
          },
          {
            key: "night_stay",
            label: (
              <div
                data-testid="fieldAction-dropdownItem_night_stay"
                className="action-dropdown-list"
                onClick={() => {
                  setActiveModule({
                    ...activeModule,
                    sub_module: activeModule.sub_module.includes("night_stay")
                      ? activeModule.sub_module
                      : [...activeModule.sub_module, "night_stay"],
                  });
                }}
              >
                <img src={NightStay} alt="stay" />
                Add Night Stay
              </div>
            ),
          },
          {
            key: "purpose",
            label: (
              <div
                data-testid="fieldAction-dropdownItem_purpose"
                className="action-dropdown-list"
                onClick={() => {
                  setActiveModule({
                    ...activeModule,
                    sub_module: activeModule.sub_module.includes("purpose")
                      ? activeModule.sub_module
                      : [...activeModule.sub_module, "purpose"],
                  });
                }}
              >
                <img src={Purpose} alt="purpose" />
                Add Purpose
              </div>
            ),
          },
        ]),
  ];

  const renderMainModules = () => {
    switch (activeModule.main_module) {
      case "LOCATION":
        return (
          <>
            <Input
              data-testid="fieldAction-area_name_input"
              placeholder="Enter Area Name"
              value={fieldValues?.module_name}
              onChange={(e) =>
                onChange({
                  module_name: e.target.value,
                })
              }
            />
            {}
          </>
        );
      case "HOLIDAY":
        return (
          <Input.TextArea
            data-testid="fieldAction-reason_textArea"
            placeholder="Reason"
            rows={3}
            value={fieldValues?.module_name}
            onChange={(e) =>
              onChange({
                module_name: e.target.value,
              })
            }
          />
        );
      case "BEAT":
        return (
          <MultiSelectSearch
            optionList={[
              ...selectBeatParams.optionList,
              ...(fieldValues?.deleted_beats_info || []),
            ]}
            listItem={(ele) => <div>{ele.name}</div>}
            onChange={(v) => {
              onChange({ beat_ids: v });
            }}
            value={fieldValues?.beat_ids || []}
            params={{
              "data-testid": "fieldAction-choose_beat_multiSelect",
              onClick: staffSelectionMessage,
              placeholder: "Choose Beat",
              maxCount: 5,
              ...(!isStaffSelected && { open: false }),
              ...selectBeatParams,
            }}
          />
        );
      default:
        return "";
    }
  };

  const renderSubModule = {
    night_stay: (
      <NightStayText
        {...{ setActiveModule, activeModule, onChange }}
        value={fieldValues?.night_stay}
      />
    ),
    purpose: (
      <PurposeText
        {...{ setActiveModule, activeModule, onChange }}
        value={fieldValues?.purpose}
      />
    ),
  };

  const staffSelectionMessage = () => {
    if (!isStaffSelected) {
      return notification.warning({
        message: "Please assign a staff before moving forward.",
      });
    }
  };

  useEffect(() => {
    onChange({
      module_type: activeModule.main_module,
      sub_module_type: activeModule.sub_module,
      ...(fieldValues.module_type !== activeModule.main_module && {
        module_name: "",
        beat_ids: [],
      }),
      ...(activeModule.main_module === "HOLIDAY" && {
        night_stay: "",
        purpose: "",
      }),
    });
  }, [activeModule]);

  return (
    <div
      style={type === "EDIT" && isDatePassed ? { cursor: "not-allowed" } : {}}
    >
      <div
        data-testid="fieldAction-choose_beat_card"
        data-value={fieldValues.date}
        className={`${styles.linear_background} ${styles.form_field_background}`}
        style={
          type === "EDIT" && isDatePassed
            ? { opacity: 0.5, pointerEvents: "none" }
            : {}
        }
      >
        <div className={styles.space_between}>
          <div
            className={styles.card_title}
            data-testid="fieldAction-selected_beat_date"
          >
            {moment(fieldValues.date).format("DD MMM YY, ddd")}
          </div>
          <div
            className={styles.flex}
            style={{ gap: "2em" }}
            data-testid="fieldAction-dropdown"
          >
            {activeModule.main_module === "LOCATION" && (
              <div
                data-testid="fieldAction-new_area_tag"
                className={`${styles.flex} ${styles.menu_tag}`}
                style={{ background: "#e8edff", color: "#6c84dd" }}
              >
                <img src={NewVisitAreaOutlinedIcon} alt="new-visit" />
                New Area
              </div>
            )}
            {activeModule.main_module === "HOLIDAY" && (
              <div
                data-testid="fieldAction-no_plan_tag"
                className={`${styles.flex} ${styles.menu_tag}`}
                style={{ background: "#FFE8E8", color: "#DC7777" }}
              >
                <img src={NoPlanOutlinedIcon} alt="HOLIDAY" />
                No Plan
              </div>
            )}

            <Dropdown
              menu={{
                items: items.map((ele) => ({
                  ...ele,
                  label: (
                    <div onClick={staffSelectionMessage}>
                      <div
                        data-testid="fieldAction-dropdown_item_list"
                        style={
                          !isStaffSelected ? { pointerEvents: "none" } : {}
                        }
                      >
                        {ele.label}
                      </div>
                    </div>
                  ),
                })),
              }}
              className="action-dropdown"
            >
              <div className="clickable">
                <MoreOutlined className="action-icon" />
              </div>
            </Dropdown>
          </div>
        </div>
        <br />
        <div className={styles.flex_col}>
          {renderMainModules()}
          {activeModule.sub_module.map((ele) => renderSubModule[ele])}
        </div>
      </div>
    </div>
  );
}

const PurposeText = ({ setActiveModule, activeModule, onChange, value }) => {
  return (
    <div className={styles.flex}>
      <Input.TextArea
        data-testid="fieldAction-purpose_textArea"
        placeholder="Enter purpose of the visit"
        rows={3}
        value={value}
        onChange={(e) =>
          onChange({
            purpose: e.target.value,
          })
        }
      />
      <div
        data-testid="fieldAction-purpose_remove_icon"
        className={styles.remove_icon}
        onClick={() => {
          setActiveModule({
            ...activeModule,
            sub_module: activeModule.sub_module.filter(
              (ele) => ele !== "purpose"
            ),
          });
          onChange({
            purpose: "",
          });
        }}
      >
        <CloseOutlined />
      </div>
    </div>
  );
};

const NightStayText = ({ setActiveModule, activeModule, onChange, value }) => {
  return (
    <div className={styles.flex}>
      <Input
        data-testid="fieldAction-nightStay_address_input"
        placeholder="Enter where you are going to stay"
        value={value}
        onChange={(e) => onChange({ night_stay: e.target.value })}
      />
      <div
        data-testid="fieldAction-nightStay_remove_icon"
        className={styles.remove_icon}
        onClick={() => {
          setActiveModule({
            ...activeModule,
            sub_module: activeModule.sub_module.filter(
              (ele) => ele !== "night_stay"
            ),
          });
          onChange({
            night_stay: "",
          });
        }}
      >
        <CloseOutlined />
      </div>
    </div>
  );
};
