import axios from "axios";
import { BASE_URL_V2, org_id } from "../../config.js";
import { feedbackAndActivity } from "../constant";
import { notification } from "antd";
import SessionExpireError from "../../helpers/sessionExpireError.js";
import Cookies from "universal-cookie";
import { headers } from "../../services/api-service.js";

// add/edit activity
export const updateActivity = async (formInput, id) => {
  const cookies = new Cookies();
  let url;
  if (id) {
    url = `${BASE_URL_V2}/organization/${org_id}/followup/${id}/`;
  } else {
    url = `${BASE_URL_V2}/organization/${org_id}/followup/`;
  }
  const data = formInput;
  try {
    const res = await axios.post(url, data, { headers });
    cookies.set("checkIn", formInput.action, { path: "/" });
    if (res.status === 200) {
      notification.success({
        message: `Activity has been ${id ? "updated" : "created"} successfully`,
      });
    }
    return res;
  } catch (err) {
    SessionExpireError(err.response);
    notification.warning({
      message: `${err.response.data.message}`,
    });
  }
};

// CHECK-IN AND CHECK-OUT needed to changes soon
export const checkInAction =
  (params = {}, checkIn = null) =>
  (dispatch) => {
    const cookies = new Cookies();
    const url = `${BASE_URL_V2}/organization/${org_id}/activity/attendance/check/`;
    const checkState = checkIn === null ? cookies.get("checkIn") : checkIn;
    const action = checkState === "Check In" ? "Check Out" : "Check In";
    const data = {
      module_type: "Attendance",
      action,
      ...params,
    };
    axios
      .post(url, data, { headers })
      .then((response) => {
        cookies.set("checkIn", action, { path: "/" });
        if (response.status === 200) {
          notification.success({
            message: `${response.data.message}`,
          });
        }
        dispatch({
          type: feedbackAndActivity.CHECK_IN,
          payload: response,
        });
        setTimeout(() => {
          dispatch({
            type: feedbackAndActivity.CHECK_IN,
            payload: "",
          });
        }, 100);
      })
      .catch((err) => {
        SessionExpireError(err.response);
        notification.warning({
          message: `${err.response?.data.message}`,
        });
      });
  };

export const fetchFeedbackActivityById = async (followup_id) => {
  if (followup_id === undefined) return;
  const url = `${BASE_URL_V2}/organization/${org_id}/followup/${followup_id}/`;
  return axios
    .get(url, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

export const getActivityFormByType = async (activityTypeId) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/custom-forms/activity/${activityTypeId}/`;

  try {
    const res = await axios.get(url, { headers });
    return res;
  } catch (err) {
    SessionExpireError(err.response);
    notification.warning({
      message: `${err.response.data.message}`,
    });
  }
};

//       team - activity services
export const getAggregatedStatusCounts = async (params = {}) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/activity/team/status/`;

  try {
    const res = await axios.get(url, { headers, params });
    return res.data.data;
  } catch (err) {
    SessionExpireError(err.response);
    notification.warning({
      message: `${err.response.data.message}`,
    });
  }
};

export const getStaffDashboardDetails = async (params = {}) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/activity/team/dashboard/`;

  try {
    const res = await axios.get(url, { headers, params });
    return res.data.data;
  } catch (err) {
    SessionExpireError(err.response);
  }
};

export const createStaffDashboardReport = async (filters = {}) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/staff/dashboard/reports/`;

  try {
    const response = await axios.post(
      url,
      { report_module: "STAFF_DASHBOARD", ...filters },
      { headers }
    );
    if (response.status === 200) {
      notification.success({
        message: `${response.data.message}`,
      });
    }
  } catch (err) {
    SessionExpireError(err.response);
  }
};

export const getTCPCLogsList = async (params = {}) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/activity/tcpc/logs/`;

  try {
    const res = await axios.get(url, { headers, params });
    return res.data.data;
  } catch (err) {
    SessionExpireError(err.response);
    notification.warning({
      message: `${err.response.data.message}`,
    });
  }
};

export const getActivityMapLogs = async (
  user_id,
  params = {},
  page_no = 1,
  page_size = 100
) => {
  if (!user_id) return [];
  const url = `${BASE_URL_V2}/organization/${org_id}/activitylog/user/${user_id}/`;
  try {
    const res = await axios.get(url, {
      headers,
      params: { with_location_points: true, ...params, page_no, page_size },
    });
    let { activity_records, extra_data } = res.data.data;
    activity_records = [
      ...activity_records,
      ...(activity_records.length === page_size
        ? (await getActivityMapLogs(user_id, params, page_no + 1, page_size)) ||
          []
        : []),
    ];
    if (page_no > 1) {
      return activity_records;
    } else {
      return {
        activity_records,
        extra_data,
      };
    }
  } catch (error) {
    SessionExpireError(error.response);
  }
};

export const getDSRDetails = async (params = {}, user_id) => {
  // if (!params?.user_id) return;

  const url = `${BASE_URL_V2}/organization/${org_id}/activitylog/dsr/${user_id}/`;

  try {
    const res = await axios.get(url, { headers, params });
    return res.data.data;
  } catch (error) {
    SessionExpireError(error.response);
  }
};

export const getDeviceLogs = async (user_id, date) => {
  if (!user_id) return;

  const url = `${BASE_URL_V2}/organization/${org_id}/activity/devicelogs/${user_id}/`;
  const params = { date };
  try {
    const res = await axios.get(url, { headers, params });
    return res.data.data;
  } catch (error) {
    SessionExpireError(error.response);
  }
};
