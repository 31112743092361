import { feedbackAndActivity } from "../constant";

const initialState = {
  data: "",
};

export const checkInReducer = (state = initialState, action) => {
  switch (action.type) {
    case feedbackAndActivity.CHECK_IN:
      return { ...state, data: action.payload };

    default:
      return { ...state };
  }
};
