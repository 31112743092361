import { BASE_URL_V2, org_id } from "../config";
import axios from "axios";
import SessionExpireError from "../helpers/sessionExpireError";
import { headers } from "./api-service";

export const updateCheckInCustomer = async () => {
  const url = `${BASE_URL_V2}/organization/${org_id}/activity/checkin/status/`;

  await axios
    .get(url, { headers })
    .then((response) => {
      const res = response.data.data;

      localStorage.setItem(
        "CheckInCustomer",
        JSON.stringify(
          res.customer_id
            ? {
                id: res.customer_id,
                name: res.customer_name,
                check_in_time: res.is_checked_in,
              }
            : {}
        )
      );
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};
