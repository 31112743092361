import axios from "axios";
import { notification } from "antd";
import { BASE_URL_V2, org_id } from "../../config.js";
import SessionExpireError from "../../helpers/sessionExpireError.js";
import { headers } from "../../services/api-service.js";

export const getBeatPlanList = async (params = {}) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/beatroute/`;

  try {
    const res = await axios.get(url, { params, headers });
    return res.data.data;
  } catch (err) {
    SessionExpireError(err.response);
  }
};

export const getBeatPlanDetails = async (id) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/beatroute/${id}/`;

  return axios
    .get(url, { headers })
    .then((res) => {
      return res;
    })
    .catch((err) => SessionExpireError(err.response));
};

export const getListAllDayBeatPlan = async (params) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/beatroute/${params.id}/days/`;

  return axios
    .get(url, { params, headers })
    .then((res) => {
      return res;
    })
    .catch((err) => SessionExpireError(err.response));
};

// Add Beat Plan
export const createBeatPlan = async (formValues) => {
  let url = "";

  if (formValues?.id) {
    url = `${BASE_URL_V2}/organization/${org_id}/beatroute/${formValues.id}/`;
  } else {
    url = `${BASE_URL_V2}/organization/${org_id}/beatroute/`;
  }

  try {
    const res = await axios.post(url, formValues, { headers });
    if (res.status === 200) {
      notification.success({
        message: res.data.message,
      });
    }
    return res;
  } catch (err) {
    SessionExpireError(err.response);
    notification.warning({
      message: err.response.data.message,
    });
  }
};

//delete beat Plan
export const deleteBeatPlan = (id) => {
  if (!id) return;
  const url = `${BASE_URL_V2}/organization/${org_id}/beatroute/${id}/delete/`;

  return axios
    .post(url, {}, { headers })
    .then((response) => {
      if (response.status === 200) {
        notification.success({
          message: `${response.data.message}`,
        });
      }
      return response;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: `${err.response.data.message}`,
      });
    });
};

// Active Beat Plan Details
export const getActiveBeatPlanDetails = async (params) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/beatroute/${params.user_id}/active/`;
  // const params = { date: date, is_active: true };
  return axios
    .get(url, { params, headers })
    .then((response) => {
      if (response.data.data?.length) return response.data.data;
      return [];
    })
    .catch((error) => {
      SessionExpireError(error.response);
    });
};

// Approval Beat Plan
export const updateBeatPlanStatus = async (formdata) => {
  const url = `${BASE_URL_V2}/organization/${org_id}/beatroute/${formdata.id}/approval/`;
  return axios
    .post(url, formdata, { headers })
    .then((response) => {
      if (response.status === 200) {
        notification.success({
          message: response.data.message,
        });
      }
      return response;
    })
    .catch((err) => {
      SessionExpireError(err.response);
      notification.warning({
        message: err.response.data.message,
      });
    });
};
