import cartService from "../services/cart-service";
import { mergeArrays } from ".";
import { calculatePercentage, roundToDecimalPlaces } from "./numbers";
import { fetchProductList } from "../redux/action/productAction";

const calculateOrderLevelDiscounts = (amountWithoutGST, discountList) => {
  let totalDiscountAmount = 0;
  for (let i = 0; i < discountList.length; i++) {
    let { value, type } = discountList[i];
    switch (type) {
      case "PERCENT":
        let discountInPercent = value / 100;
        // Reduce the percentage from both ratios
        let discount = amountWithoutGST * discountInPercent;
        totalDiscountAmount += discount;
        break;
      case "AMOUNT":
        totalDiscountAmount += value;
        break;
    }
  }
  return totalDiscountAmount;
};

export function calculateProductBuyerPrice(product, withDiscount = false) {
  if (Object?.keys(product)?.length < 2) return;

  let price = product.price;
  const discount = product?.discount_value || 0;

  const cartData = cartService.fetchCartItem(product.id) || {};

  const qty =
    (cartData?.qty || 1) *
    (cartData?.packaging_size ||
      product?.packaging_size ||
      product?.packaging_level?.[0]?.size);

  product.telescope_pricing.forEach((ele) => {
    if (qty >= ele.qty) price = ele.price;
  });

  return withDiscount ? price - discount : price;
}

const calculateProductLevelPrice = (
  buyerPrice,
  gst,
  gstExclusive,
  qty,
  packaging_size
) => {
  let units = qty * packaging_size;
  if (gstExclusive) {
    const exclusiveGstAmount = (buyerPrice * gst) / 100;
    return qty
      ? {
          productGstAmount: exclusiveGstAmount * units,
          productPriceWithoutGST: buyerPrice * units,
          productPriceWithGST: (buyerPrice + exclusiveGstAmount) * units,
        }
      : {
          productGstAmount: exclusiveGstAmount,
          productPriceWithoutGST: buyerPrice,
          productPriceWithGST: buyerPrice + exclusiveGstAmount,
        };
  } else {
    const inclusiveGstAmount = (buyerPrice * gst) / (100 + gst);
    return qty
      ? {
          productGstAmount: inclusiveGstAmount * units,
          productPriceWithoutGST: (buyerPrice - inclusiveGstAmount) * units,
          productPriceWithGST: buyerPrice * units,
        }
      : {
          productGstAmount: inclusiveGstAmount,
          productPriceWithoutGST: buyerPrice - inclusiveGstAmount,
          productPriceWithGST: buyerPrice,
        };
  }
};

export function productCalculations(product) {
  const { productDiscount, gst, gst_exclusive, qty, packaging_size } = product;
  let productPrice = calculateProductBuyerPrice(
    product,
    productDiscount ? true : false
  );
  const { productPriceWithoutGST, productPriceWithGST, productGstAmount } =
    calculateProductLevelPrice(
      productPrice,
      gst,
      gst_exclusive,
      qty,
      packaging_size
    );
  return {
    productPriceWithoutGST,
    productPriceWithGST,
    productGstAmount,
  };
}

export const cartCalculations = (
  amountWithoutGST,
  { discountList, otherChargesList }
) => {
  // calculate order level discount amount and percentage
  let totalDiscountAmount = calculateOrderLevelDiscounts(
    amountWithoutGST,
    discountList
  );
  let totalDiscountPercent = (totalDiscountAmount / amountWithoutGST) * 100;

  // calculate final order amount after applying order level discounts
  let discountedAmountWithoutGST = Number(
    (amountWithoutGST - totalDiscountAmount).toFixed(2)
  );

  // calculate final GST amount after applying order level discounts
  let discountedGSTAmount = 0;
  let items = cartService.fetchCart();
  for (let i = 0; i < items.length; i++) {
    let { productPriceWithoutGST } = productCalculations(items[i]);
    // calculate product level price with order level discounts
    let discountAmount = calculatePercentage(
      productPriceWithoutGST,
      totalDiscountPercent
    );
    let updatedProductPrice = productPriceWithoutGST - discountAmount;
    // calculate product level GST after order level discounts
    let updatedProductGst = calculatePercentage(
      updatedProductPrice,
      items[i]["gst"]
    );
    discountedGSTAmount += updatedProductGst;
  }

  // return updated cart summary
  return {
    totalPriceWithoutGstAmount: discountedAmountWithoutGST,
    totalGSTAmount: roundToDecimalPlaces(discountedGSTAmount),
    totalPriceWithGstAmount: roundToDecimalPlaces(
      discountedAmountWithoutGST +
        discountedGSTAmount +
        otherChargesList?.reduce(
          (accumulator, current) => accumulator + current.value,
          0
        ),
      2
    ),
  };
};

export const handleEditOrder = async (orderDetail, callback) => {
  // updated product details from BE
  let response = await fetchProductList({
    ids: [...orderDetail.items.map((item) => item.id)].join(","),
  });
  response = response.data.map((item) => ({
    id: item.id,
    is_out_of_stock: item.is_out_of_stock,
  }));
  const updatedProducts = mergeArrays(orderDetail.items, response).map(
    (item) => ({
      ...item,
      initial_qty: item.qty,
      // set up price and discount details for each product
      price: item.original_price,
      ...(item.discount_value
        ? {
            productDiscount: item.price - (item.discount_value || 0),
          }
        : {}),
    })
  );

  // setup cart for order edit
  cartService.initCart(orderDetail.customer, {
    discountList: orderDetail.discount_details || [],
    otherChargesList: orderDetail.charges_details || [],
    orderId: orderDetail.id,
    orderDetail: orderDetail,
    isDraft: false,
  });
  updatedProducts.map((product) => {
    cartService.updateCartItem(product);
  });

  callback && callback();
};
