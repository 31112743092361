const CustomerIcon = ({ fill = "#727176", size = "24" }) => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <mask
        id="mask0_1144_429"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="24"
        height="24"
      >
        <path d="M26.5426 3.75H3.33325V26.5327H26.5426V3.75Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1144_429)">
        <path
          d="M18.8812 8.18706C18.8811 9.2136 18.5646 10.2083 17.9857 11.0018C17.4067 11.7953 16.6011 12.3384 15.7062 12.5385C14.8112 12.7387 13.8822 12.5835 13.0776 12.0995C12.2729 11.6155 11.6423 10.8325 11.2932 9.88409C10.9441 8.93564 10.8982 7.88036 11.1632 6.89806C11.4282 5.91577 11.9877 5.06723 12.7465 4.49705C13.5053 3.92687 14.4163 3.67032 15.3244 3.77111C16.2325 3.8719 17.0814 4.3238 17.7265 5.04981C18.4659 5.88195 18.8813 7.01044 18.8812 8.18706Z"
          {...{ fill }}
        />
        <path
          d="M25.41 9.81901C25.41 10.3738 25.2638 10.9161 24.9898 11.3774C24.7158 11.8387 24.3264 12.1982 23.8708 12.4105C23.4152 12.6228 22.9139 12.6783 22.4303 12.5701C21.9466 12.4619 21.5023 12.1947 21.1536 11.8024C20.8049 11.4102 20.5675 10.9104 20.4712 10.3662C20.375 9.82212 20.4244 9.25813 20.6131 8.74558C20.8019 8.23303 21.1214 7.79495 21.5315 7.48673C21.9415 7.17852 22.4235 7.01401 22.9167 7.01401C23.5779 7.01401 24.2121 7.30953 24.6797 7.83557C25.1473 8.36161 25.41 9.07508 25.41 9.81901Z"
          {...{ fill }}
        />
        <path
          d="M9.45322 9.81901C9.45322 10.3738 9.30699 10.9161 9.03302 11.3774C8.75905 11.8387 8.36964 12.1982 7.91404 12.4105C7.45845 12.6228 6.95712 12.6783 6.47346 12.5701C5.9898 12.4619 5.54553 12.1947 5.19683 11.8024C4.84814 11.4102 4.61067 10.9104 4.51446 10.3662C4.41826 9.82212 4.46763 9.25813 4.65635 8.74558C4.84506 8.23303 5.16464 7.79495 5.57466 7.48673C5.98469 7.17852 6.46675 7.01401 6.95989 7.01401C7.62116 7.01401 8.25535 7.30953 8.72294 7.83557C9.19053 8.36161 9.45322 9.07508 9.45322 9.81901Z"
          {...{ fill }}
        />
        <path
          d="M9.41592 15.1418C8.43459 14.2373 7.54592 14.3573 6.41125 14.3573C5.59766 14.3555 4.81666 14.7168 4.23961 15.3621C3.66256 16.0073 3.33661 16.8837 3.33325 17.799V23.3685C3.33466 23.7653 3.47576 24.1453 3.72566 24.4253C3.97555 24.7053 4.31389 24.8625 4.66659 24.8625C7.84059 24.8625 7.45859 24.927 7.45859 24.7088C7.45859 20.7623 7.04325 17.868 9.41659 15.1425"
          {...{ fill }}
        />
        <path
          d="M16.0179 14.3813C14.0359 14.1953 12.3132 14.3813 10.8272 15.7635C8.34055 18.0045 8.81921 21.0217 8.81921 24.7125C8.81992 25.1859 8.98738 25.6396 9.28491 25.9743C9.58244 26.3091 9.98577 26.4975 10.4065 26.4983C19.9752 26.4983 20.3559 26.8455 20.9232 25.4317C21.1092 24.954 21.0565 25.1055 21.0565 20.5327C21.0565 16.9005 18.2612 14.3828 16.0165 14.3828"
          {...{ fill }}
        />
        <path
          d="M23.4646 14.358C22.324 14.358 21.44 14.2395 20.46 15.1433C22.8153 17.8493 22.4179 20.5433 22.4179 24.7095C22.4179 24.9293 22.1006 24.8633 25.1626 24.8633C25.528 24.8637 25.8786 24.701 26.1374 24.4109C26.3963 24.1208 26.5422 23.727 26.5433 23.316V17.7998C26.5399 16.8845 26.214 16.0081 25.6369 15.3628C25.0599 14.7176 24.2789 14.3562 23.4653 14.358"
          {...{ fill }}
        />
      </g>
    </svg>
  );
};

export default CustomerIcon;
