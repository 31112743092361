import { useState } from "react";
import { Button, Drawer, Form, Input } from "antd";
import apiService from "../../../services/api-service";
import { regex } from "../../../components/form-elements/regex";
import FormInput from "../../../components/form-elements/formInput";
import formLabels from "../../constants/labels.json";
import errorMsgs from "../../constants/messages.json";

const { TextArea } = Input;

export default function ContactUsForm({ viewContactForm, setViewContactForm }) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setViewContactForm(false);
    form.resetFields();
  };

  const handleSubmit = async (v) => {
    setLoading(true);
    const res = await apiService.contactUs(v);
    if (res) onClose();
    setLoading(false);
  };

  return (
    <Drawer
      title={<div style={{ textAlign: "center" }}>Contact Us</div>}
      width={520}
      onClose={onClose}
      open={viewContactForm}
    >
      <Form
        form={form}
        colon={false}
        layout="vertical"
        requiredMark={(label, info) => (
          <div>
            {label} {info.required && <span style={{ color: "red" }}>*</span>}
          </div>
        )}
        validateMessages={{
          required: "${label} is required.",
        }}
        onFinish={handleSubmit}
        style={{ padding: "20px 30px" }}
      >
        <Form.Item
          label={formLabels.CONTACT_US.name}
          name="name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          label={formLabels.CONTACT_US.email}
          name="email"
          rules={[
            { required: true, message: "" },
            {
              validator: (_, value) => {
                if (value?.match(regex["email"])) return Promise.resolve();
                return Promise.reject(new Error(errorMsgs.GENERAL.EMAIL_ERROR));
              },
            },
          ]}
        >
          <Input placeholder="Enter Email" />
        </Form.Item>
        <Form.Item
          label={formLabels.CONTACT_US.phone}
          name="mobile"
          rules={[
            { required: true, message: false },
            {
              validator: (_, value) => {
                if (value?.length === 10) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(errorMsgs.GENERAL.MOBILE_ERROR)
                );
              },
            },
          ]}
        >
          <FormInput
            type="num"
            params={{ maxLength: 10, placeholder: "Enter Mobile Number" }}
          />
        </Form.Item>
        <Form.Item
          label={formLabels.CONTACT_US.message}
          name="message"
          rules={[{ required: true }]}
        >
          <TextArea
            rows={4}
            placeholder="Enter Message"
            style={{ resize: "none" }}
          />
        </Form.Item>

        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form>
    </Drawer>
  );
}
