import { Col, Collapse, ConfigProvider, Space } from "antd";
import { useState } from "react";
import Sider from "antd/es/layout/Sider";
import { useNavigate } from "react-router-dom";
import LogoIcon from "../../assets/logo-theme.png";
import packageInfo from "../../../../package.json";
import { DownOutlined } from "@ant-design/icons";
import navbarItems from "./navbarItems";

const constants = {
  navbarStyles: {
    indentation: 35,
  },
};

export default function SideNavBar() {
  const navigate = useNavigate();
  const menuItems = navbarItems();
  const [collapsed, setCollapsed] = useState(true);

  const onNavigate = (obj) => {
    if (obj.key.startsWith("https://")) window.open(obj.key, "_blank");
    else navigate(`/web${obj.key}`);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            headerPadding: 0,
            contentPadding: "8px 0px",
          },
        },
      }}
    >
      <Sider
        collapsible
        trigger={null}
        className="side-navbar refactor"
        onMouseEnter={() => setCollapsed(false)}
        onMouseLeave={() => setCollapsed(true)}
        {...{ collapsed }}
        collapsedWidth={80}
        width={300}
        style={{
          padding: 8,
          ...(!collapsed && {
            boxShadow: "var(--app-shadow-left-bottom)",
          }),
        }}
      >
        {menuItems.map((item) => {
          if (item)
            return (
              <>
                {item?.children?.length ? (
                  <Collapse
                    key={item.key}
                    ghost={true}
                    expandIconPosition={"end"}
                    expandIcon={({ isActive }) => (
                      <DownOutlined
                        className="collapse-item"
                        rotate={isActive ? 180 : 0}
                      />
                    )}
                    {...(collapsed && {
                      activeKey: [],
                    })}
                    data-testid="sidenavBar-item_title"
                  >
                    <Collapse.Panel
                      header={<NavbarItem {...{ item }} />}
                      key={item.key}
                    >
                      {item.children.map(
                        (ele) =>
                          ele && (
                            <Col
                              key={ele.key}
                              role="button"
                              onClick={() => onNavigate(ele)}
                              className="collapse-item"
                              style={{ minWidth: 200 }}
                            >
                              <h4
                                data-testid="sidenavBar-item_subtitle"
                                className="subtitle"
                                style={{
                                  fontWeight: 600,
                                  paddingLeft:
                                    constants.navbarStyles.indentation,
                                  ...(isRouteActive(ele.pathnames) && {
                                    color: "#fff",
                                  }),
                                }}
                                item-active={
                                  isRouteActive(ele.pathnames) && "true"
                                }
                              >
                                {ele.label}
                              </h4>
                            </Col>
                          )
                      )}
                    </Collapse.Panel>
                  </Collapse>
                ) : (
                  <Col
                    key={item.key}
                    role="button"
                    onClick={() => onNavigate(item)}
                  >
                    <NavbarItem {...{ item }} />
                  </Col>
                )}
              </>
            );
        })}
        <Space direction="vertical" className="center" style={{ height: 80 }}>
          <div className="center" style={{ flexDirection: "row" }}>
            <Col
              style={{ width: 90 }}
              className="ant-collapse-content collapse-item"
            >
              Powered by
            </Col>
            <img src={LogoIcon} alt="rupyz" width={50} />
          </div>
          <div
            className="ant-collapse-content collapse-item"
            data-testid="sidenavBar-package_info"
          >
            V {packageInfo.version}
          </div>
        </Space>
      </Sider>
    </ConfigProvider>
  );
}

const NavbarItem = ({ item }) => {
  const isActive = isRouteActive(item.pathnames);
  return (
    <Space item-active={isActive && "true"} size="small" align="middle">
      <Col style={{ height: 24 }} data-testid="sidenavBar-item_icon">
        <item.icon
          {...(isActive && {
            fill: "#fff",
          })}
        />
      </Col>
      <h4
        data-testid="sidenavBar-collapse_item"
        className="subtitle collapse-item"
        style={{
          whiteSpace: "nowrap",
          fontWeight: 600,
          ...(isActive && { color: "#fff" }),
        }}
      >
        {item.label}
      </h4>
    </Space>
  );
};

const isRouteActive = (routeList) => {
  let currentPath = (window.location.pathname || "").replace(/\/$/, "");
  currentPath = currentPath === "/web" ? "/" : currentPath.replace("/web", "");

  return (routeList || []).includes(currentPath);
};
