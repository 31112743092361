import Cookies from "universal-cookie";

const constants = {
  key: "preferences-v1",
};

const cookies = new Cookies();

const fetchPreferences = (key = "") => {
  //returns single preference value if key is provided, else returns all preferences
  const tempList = cookies.get(constants.key) || {};
  return key ? tempList?.[key] : tempList;
};

const updatePreferences = (data = {}) => {
  // update key value pairs in preferences
  cookies.set(constants.key, { ...fetchPreferences(), ...data }, { path: "/" });
};

export const preference = {
  get: fetchPreferences,
  set: updatePreferences,
};
