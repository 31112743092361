const ProductIcon = ({ fill = "#727176", size = "24" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75099 5.53586L4.21408 8.30431L15.0145 14.1545L20.5514 11.3861L9.75099 5.53586ZM3.00032 9.35278C3.00011 9.36013 3 9.36749 3 9.37488V20.6249C3 20.909 3.1605 21.1687 3.41459 21.2957L14.2499 26.7134V15.4463L3.00032 9.35278ZM15.7499 26.7135L26.5854 21.2957C26.8395 21.1687 27 20.909 27 20.6249V9.83885L22.6249 12.0264V15.625C22.6249 16.0392 22.2891 16.375 21.8749 16.375C21.4606 16.375 21.1249 16.0392 21.1249 15.625V12.7764L15.7499 15.4639V26.7135ZM26.2504 8.53658L15.3354 3.07906C15.1243 2.97348 14.8757 2.97348 14.6646 3.07906L11.3887 4.71702L22.1891 10.5673L26.2504 8.53658Z"
        {...{ fill }}
      />
    </svg>
  );
};

export default ProductIcon;
