export default function LeadIcon({ fill = "#727176", size = "24" }) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <path
        d="M11.5 3C14.25 3 16.5 5.25 16.5 8C16.5 10.75 14.25 13 11.5 13C8.75 13 6.5 10.75 6.5 8C6.5 5.25 8.75 3 11.5 3ZM20.25 24.25L22.5 26.4625C23.125 27.0875 24 26.5875 24 25.85V20.5L27.5 16.25C27.6393 16.0643 27.7241 15.8435 27.7449 15.6123C27.7658 15.3811 27.7219 15.1486 27.618 14.941C27.5142 14.7334 27.3546 14.5587 27.1572 14.4367C26.9597 14.3146 26.7321 14.25 26.5 14.25H17.75C16.75 14.25 16.125 15.5 16.75 16.25L20.25 20.5V24.25ZM17.75 21.375L14.875 17.875C14.375 17.25 14.125 16.5 14.125 15.75C13.25 15.5 12.375 15.5 11.5 15.5C6 15.5 1.5 17.75 1.5 20.5V23H17.75V21.375Z"
        {...{ fill }}
      />
    </svg>
  );
}
